// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const AllNotification = createAsyncThunk(
  "account/notification",
  async () => {
    const response = await axios.get("/api/notification/list/all-data")
    return response.data
  }
)

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    allData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AllNotification.fulfilled, (state, action) => {
      state.allData = action.payload
    })
  },
})

export default notificationSlice.reducer
