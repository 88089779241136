// ** Icons Import
import {
  Box,
  Mail,
  User,
  Circle,
  Shield,
  Calendar,
  FileText,
  CheckSquare,
  ShoppingCart,
  MessageSquare,
  File,
  Grid,
  Edit3
} from "react-feather"

export default [
  {
    id: "master",
    title: "Master Data",
    icon: <Grid size={14} />,
    children: [
      {
        id: "esign",
        title: "eSign",
        icon: <Edit3 size={14} />,
        navLink: "/master/esign"
      }
    ]
  }
]
