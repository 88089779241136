// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@configs/axios"
const ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/transaction`
const entity = "Transaction"

export const AllTransaction = createAsyncThunk("plan/purchase", async () => {
  const user = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.get(`${ENDPOINT}`, {
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
  })
  return response.data
})

export const transactionSlice = createSlice({
  name: "transactions",
  initialState: {
    allData: [],
    loading: true,
    loading_form: true,
    error: "",
    success: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AllTransaction.fulfilled, (state, action) => {
      state.allData = action.payload
      state.loading = false
      state.success = ``
      state.error = ``
    })
  },
})

export default transactionSlice.reducer
