// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@configs/axios"
const ENDPOINT = `/api/ticket-details`
const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjozNCwicm9sZV9pZCI6IjEiLCJjb21wYW55X2lkIjoiMjIiLCJpYXQiOjE2NzEyOTIyMjgsImV4cCI6MTY3MTM3ODYyOH0.WpE6YSoBwd6pZEreP0Run4d-bgIFwpaUNESUBsHz8lY`

export const getDetailTickets = createAsyncThunk("ticket/view", async (id) => {
  const user = JSON.parse(localStorage.getItem("userData"))
  const response = await axios.get(`${ENDPOINT}/${id}`, {
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
  })
  return response.data
})

export const addTicket = createAsyncThunk(
  "ticket/add",
  async (data, { dispatch }) => {
    await axios.post(ENDPOINT, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    // await dispatch(getAllUsers())
    return data
  }
)

export const ticketSlice = createSlice({
  name: "detailTicket",
  initialState: {
    allData: [],
    loading: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDetailTickets.fulfilled, (state, action) => {
      state.allData = action.payload.data
      state.loading = false
    })
  },
})

export default ticketSlice.reducer
