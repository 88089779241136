// ** Icons Import
import { Home, Activity, ShoppingCart, Box } from "react-feather"

export default [
  {
    header: "Quota"
  },
  {
    id: "quota",
    title: "Quota",
    icon: <Box />,
    children: [
      {
        id: "quotaTransfer",
        title: "Transfer",
        // icon: <ShoppingCart />,
        navLink: "/quota/transfer"
      }
    ]
  }
]
