// ** Icons Import
import { Send, ShoppingCart } from "react-feather"

export default [
  {
    id: "quota",
    title: "Quota",
    icon: <ShoppingCart size={14} />,
    children: [
      {
        id: "quotaPurchase",
        title: "Purchase",
        icon: <ShoppingCart size={14} />,
        navLink: "/quota/purchase",
      },
      {
        id: "quotaTransfer",
        title: "Transfer",
        icon: <Send size={14} />,
        navLink: "/quota/transfer",
      },
    ],
  },
]
