// ** Icons Import
import { Home, Activity, ShoppingCart, FileText, FilePlus } from "react-feather"

export default [
  {
    id: "stamp",
    title: "My e-Document",
    icon: <FileText size={14} />,
    children: [
      {
        id: "singleStamp",
        title: "Single Stamp",
        icon: <FileText size={14} />,
        navLink: "/stamp"
      },
      // {
      //   id: "singleStamp",
      //   title: "Single Dev",
      //   icon: <Activity />,
      //   navLink: "/stamp/dev",
      // },
      {
        id: "bulkStamp",
        title: "Bulk Stamp",
        icon: <FilePlus size={14} />,
        navLink: "/stamp/bulk"
      }
      // {
      //   id: "bulkStamp",
      //   title: "Bulk Stamp Dev",
      //   icon: <ShoppingCart />,
      //   navLink: "/stamp/bulk/dev",
      // },
    ]
  }
]
