// ** Icons Import
import { Send, ShoppingBag, ShoppingCart } from "react-feather"

export default [
  {
    id: "plan",
    title: "Quota",
    icon: <ShoppingCart size={14} />,
    navLink: "/plan",
    children: [
      {
        id: "planPersonal",
        title: "Buy Package",
        icon: <ShoppingBag size={14} />,
        navLink: "/plan"
      },
      {
        id: "transferPersonal",
        title: "Transfer Kuota",
        icon: <Send size={14} />,
        navLink: "/transfer"
      }
    ],
  },
]
