// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@configs/axios"
const entity = "Folder"

export const getAllFolder = createAsyncThunk("digital-locker/view", async (type) => {
  const user = JSON.parse(localStorage.getItem("userData"))
  let ENDPOINT
  if (type) {
    ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/file-manager?dropdown=true`
  } else {
    ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/file-manager`
  }
  const response = await axios.get(ENDPOINT, {
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
  })
  return response.data.data
})

export const addFolder = createAsyncThunk(
  "digital-locker/add",
  async (data, { dispatch, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"))
    const ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/file-manager`
    try {
      await axios.post(ENDPOINT, data, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
      await dispatch(getAllFolder())
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const getFileListByID = createAsyncThunk(
    "digital-locker/get/detail", async (params, { dispatch, rejectWithValue }) => {
      const user = JSON.parse(localStorage.getItem("userData"))
      const ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/file-manager/folder/${params.file_manager_id}?search=${params.search}`
      try {
        const res = await axios.get(ENDPOINT, {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
        // await dispatch(getAllFolder())
        // console.log('>>> ', res.data.data)
        // return res?.data?.data?.data
        return {
            files: res?.data?.data?.files,
            folder: res?.data?.data?.folder
        }
      } catch (err) {
        return rejectWithValue(err.response.data)
      }
    }
)

export const getStorageUsage = createAsyncThunk(
  "digital-locker/get/storage-usage", async (params, { dispatch, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"))
    const ENDPOINT = `/api/digital-loker/storage-use`
    try {
      const res = await axios.post(ENDPOINT, {}, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
      // await dispatch(getAllFolder())
      return res.data.data
      
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const renameFile = createAsyncThunk(
  "digital-locker/rename-file", 
  async (payload, { dispatch, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"))
    const ENDPOINT = `/api/digital-loker/rename-file`
    try {
      const res = await axios.post(ENDPOINT, payload, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
      await dispatch(getFileListByID({
        file_manager_id: payload.file_manager_id, 
        search: payload.search
      }))
      return res.data.data
      
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const getDocumentDetail = createAsyncThunk(
    "digital-locker/get/document-detail", async  (params, { dispatch, rejectWithValue }) => {
      const user = JSON.parse(localStorage.getItem("userData"))
      const ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/get-document-details/${params.documentID}`
      try {
        const res = await axios.get(ENDPOINT, {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
        return res?.data?.data
      } catch (err) {
        return rejectWithValue(err.response.data)
      }
    }
)

export const digitalLockerSlice = createSlice({
  name: "digitalLocker",
  initialState: {
    all_folder: [],
    folder: [],
    files: [],
    error: "",
    success: "",
    storage_usage: 0,
    storage_max: 0,
    loading_directory: false,
    loading_storage: false,
    loading_form: false,
    loading_files: false,
    loading_document: false,
    document_detail: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllFolder.fulfilled, (state, action) => {
      state.all_folder = action.payload
      state.loading_directory = false
    })
    builder.addCase(getAllFolder.pending, (state, action) => {
      state.loading_directory = true
    })
    builder.addCase(getAllFolder.rejected, (state, action) => {
      state.loading_directory = false
    })
    builder.addCase(getStorageUsage.fulfilled, (state, action) => {
        state.storage_max = action.payload.max_size_digital_loker
        state.storage_usage = action.payload.size_use_user
        state.loading_storage = false
    })
    builder.addCase(getStorageUsage.pending, (state, action) => {
      state.loading_storage = true
    })
    builder.addCase(getStorageUsage.rejected, (state, action) => {
      state.loading_storage = false
    })
    builder.addCase(getFileListByID.fulfilled, (state, action) => {
      state.files = action.payload.files
      state.folder = action.payload.folder
      state.loading_files = false
  })
    builder.addCase(getFileListByID.pending, (state, action) => {
        state.loading_files = true
    })
    builder.addCase(getFileListByID.rejected, (state, action) => {
        state.loading_files = false
    })
    builder.addCase(getDocumentDetail.fulfilled, (state, action) => {
        state.document_detail = action.payload
        state.loading_document = false
    })
    builder.addCase(getDocumentDetail.pending, (state, action) => {
        state.loading_document = true
    })
    builder.addCase(getDocumentDetail.rejected, (state, action) => {
        state.loading_document = false
    })
    builder.addCase(addFolder.rejected, (state, action) => {
      state.error = action.payload.errors.status
      state.loading_form = false
      state.success = ""
    })
    builder.addCase(addFolder.pending, (state, action) => {
      state.loading_form = true
    })
    builder.addCase(addFolder.fulfilled, (state, action) => {
      state.loading_form = false
      state.error = ""
      state.success = `Berhasil tambah data ${entity}`
    })
  },
})

export default digitalLockerSlice.reducer
