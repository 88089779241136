import React from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import logo from "@src/assets/images/logo/digitax_logo.png"
import { Link } from 'react-router-dom'
import './style.css'
import { ArrowRight, Home } from 'react-feather'

const CustomFooter = () => {

    const data = {
        alamat: `Telkom Landmark Tower 2, 41st Floor <br/>Jl. Jendral Gatot Subroto Kav. 52 Jakarta Selatan 12710 Indonesia`,
        email: `salestelkompajakku@metra.co.id`,
        phone: `(021) 5210123`,
        whatsapp: `+62`
    }

    return (
        <div className='footer'>
            <Container fluid={'sm'}>
                <Row>
                    <Col sm={6} md={2}>
                        <Link to={'/'}>
                            <img
                                src={logo}
                                className="image-logo-footer"
                            />
                        </Link>
                    </Col>
                    <Col sm={6} md={4}>
                        <div>
                            <h3>The Telkom Hub</h3>
                        </div>
                        <p>
                            Telkom Landmark Tower 2, 41st Floor
                            Jl. Jendral Gatot Subroto Kav. 52 Jakarta Selatan 12710 Indonesia
                        </p>
                    </Col>
                    <Col sm={6} md={3}>
                        <div>
                            <h3>Bantuan dan Panduan</h3>
                        </div>
                        <div>
                            <div>
                                <Link to={'/terms-and-conditions'}>
                                    Syarat dan Ketentuan
                                </Link>
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <Link to={'/privacy-policy'}>
                                    Kebijakan Privasi
                                </Link>
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <Link to={'/return-policy'}>
                                    Kebijakan Pengembalian Kuota eMeterai
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} md={3}>
                        <div>
                            <h3>Hubungi Kami</h3>
                        </div>
                        <div>
                            <div>
                                Email : {data.email}
                            </div>
                            <div style={{ marginTop: 10 }}>
                                Telp : {data.phone}
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <a href={`https://wa.me/${data.whatsapp}`} target={`_blank`}>
                                    <Button.Ripple color='primary'>
                                        <span className='align-middle me-25'>Contact Us</span>
                                        <ArrowRight size={14} />
                                    </Button.Ripple>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CustomFooter