import axios from "axios"
export const customAxios = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT
})

customAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("userData")
      window.location.href = "/login"
    }
    return Promise.reject(error)
  }
)

export default customAxios
