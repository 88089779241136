// ** Icons Import
import { ChevronsRight, Info } from "react-feather"

export default [
  {
    id: "support",
    title: "Support",
    icon: <Info size={14} />,
    children: [
      {
        id: "faq",
        title: "FAQ",
        icon: <ChevronsRight size={14} />,
        navLink: "/support/faq",
      },
      {
        id: "knowledgeBase",
        title: "Knowledge Base",
        icon: <ChevronsRight size={14} />,
        navLink: "/support/knowledge-base",
      },
      {
        id: "raiseSupport",
        title: "Ticket",
        icon: <ChevronsRight size={14} />,
        navLink: "/support/ticket",
      },
    ],
  },
]
