import { lazy } from "react"

const Ticket = lazy(() => import("../../views/emeterai/support/ticket-support"))
const DetailTicket = lazy(() =>
  import("../../views/emeterai/support/ticket/detail")
)
const FAQ = lazy(() => import("../../views/emeterai/support/faq"))
const KnowledgeBase = lazy(() =>
  import(
    "../../views/emeterai/support/knowledge-base/KnowledgeBaseCategoryQuestion"
  )
)

const SupportRoutes = [
  {
    path: "/support/faq",
    element: <FAQ />
  },
  {
    path: "/support/knowledge-base",
    element: <KnowledgeBase />
  },
  {
    path: "/support/ticket",
    element: <Ticket />,
    meta: {
      appLayout: true,
      className: "email-application"
    }
  },
  {
    path: "/support/ticket/:id",
    element: <DetailTicket />
  }
]

export default SupportRoutes
