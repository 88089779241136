// ** Icons Import
import {
  Box,
  Mail,
  User,
  Circle,
  Shield,
  Calendar,
  FileText,
  CheckSquare,
  ShoppingCart,
  MessageSquare,
  File,
  Edit3,
} from "react-feather"

export default [
  {
    id: "esign",
    title: "Master eSign",
    icon: <Edit3 size={14} />,
    navLink: "/master/esign",
    children: [],
  },
]
