// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Axios Imports
import axios from "@configs/axios"
import { url } from "../../utility/Utils"

export const getSubs = createAsyncThunk("get-subscription", async () => {
  const user = JSON.parse(localStorage.getItem("userData"))
  const response = await axios.get(`${url.getSubscriptionUser}${user.id}`, {
    headers: {
      Authorization: `Bearer ${user.accessToken}`
    }
  })
  const result = response?.data?.data
  if (result) {
    return {
      plan: result.subscription_id,
      plan_name: result.subscription_name,
      status: true,
      note: "valid",
      is_esign: parseInt(result.total_esign) > 0 ? true : false,
      is_childUser: parseInt(result.total_multiuser) > 0 ? true : false,
      is_psre: parseInt(result.total_psre) > 0 ? true : false,
      // is_psre: parseInt(result.total_psre) > 0 ? true : false,
      is_multiSign: result.is_multiple_esign,
      is_parent: result.is_parent,
      is_digital_locker: result?.is_digital_locker,
      expiry_digital_locker_date: result?.expiry_digital_locker_date,
      total_psre: result.total_psre,
      total_esign: result.total_esign,
      total_childUser: result.total_multiuser,
      // company check features
      emeterai_feature: result?.is_emeterai,
      sign_certificate_feature: result?.is_esign_certificate,
      sign_spesimen_feature: result?.is_esign_spesimen,
      multi_signer_feature: result?.is_multi_sign
    }
  }
  return {
    plan: null,
    plan_name: null,
    status: false,
    end_date: null,
    note: "",
    is_esign: false,
    is_childUser: false,
    is_psre: false,
    is_multiSign: false,
    is_parent: 0,
    is_digital_locker: false,
    expiry_digital_locker_date: null,
    total_psre: 0,
    total_esign: 0,
    total_childUser: 0,
    // company check features
    emeterai_feature: false,
    sign_certificate_feature: false,
    sign_spesimen_feature: false,
    multi_signer_feature: false
  }
})

export const planSlice = createSlice({
  name: "plan",
  initialState: {
    plan: null,
    plan_name: null,
    status: false,
    end_date: null,
    note: "",
    is_esign: false,
    is_childUser: false,
    is_psre: false,
    is_multiSign: false,
    is_parent: 0,
    is_digital_locker: false,
    expiry_digital_locker_date: null,
    total_psre: 0,
    total_esign: 0,
    total_childUser: 0,
    // company check features
    emeterai_feature: false,
    sign_certificate_feature: false,
    sign_spesimen_feature: false,
    multi_signer_feature: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubs.fulfilled, (state, action) => {
      state.plan = action.payload.plan
      state.plan_name = action.payload.plan_name
      state.status = action.payload.status
      state.end_date = action.payload.end_date
      state.note = action.payload.note
      state.is_esign = action.payload.is_esign
      state.is_childUser = action.payload.is_childUser
      state.is_psre = action.payload.is_psre
      state.is_parent = action.payload.is_parent
      state.is_digital_locker = action.payload.is_digital_locker
      state.expiry_digital_locker_date = action.payload.expiry_digital_locker_date
      state.total_psre = action.payload.total_psre
      state.total_esign = action.payload.total_esign
      state.total_childUser = action.payload.total_childUser
      state.is_multiSign = action.payload.is_multiSign
      // state.draft = {}
      // company check features
      state.emeterai_feature = action.payload.emeterai_feature
      state.sign_certificate_feature = action.payload.sign_certificate_feature
      state.sign_spesimen_feature = action.payload.sign_spesimen_feature
      state.multi_signer_feature = action.payload.multi_signer_feature
    })
  }
})

export default planSlice.reducer
