// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@configs/axios"
import { url } from "@utils"
import { AllTransfer } from "../../../../quota/transfer/store"

export const AllStamping = createAsyncThunk(
  "report/stamping-history",
  async () => {
    const user = JSON.parse(localStorage.getItem("userData"))
    const response = await axios.get(`${url.listStamp}`, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`
      }
    })
    const result = response.data.data
    const nostamp = result.filter((item) => item.status === "nostamp")
    return {
      all: result,
      nostamp: nostamp
    }
  }
)

export const stampDraft = createAsyncThunk(
  "stamp/draft",
  async (payload, { dispatch, getState }) => {
    return payload
  }
)

export const lastDocument = createAsyncThunk(
  "stamp/last",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/dashboard/get-document-dashboard`
      const user = JSON.parse(localStorage.getItem("userData"))
      const res = await axios.get(ENDPOINT, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`
        }
      })
      const result = res.data.data
      return result
    } catch (err) {
      return rejectWithValue(err.response.data)
      // console.log(err.response)
    }
  }
)

export const cancelDocument = createAsyncThunk(
  "stamp/cancel",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/canceled-stamp`
      const user = JSON.parse(localStorage.getItem("userData"))
      await axios.post(
        `${ENDPOINT}`,
        { document_id: id },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`
          }
        }
      )
      await dispatch(AllStamping())
      await dispatch(lastDocument())
      await dispatch(AllTransfer())
      return id
    } catch (err) {
      return rejectWithValue(err.response.data)
      // console.log(err.response)
    }
  }
)

export const deleteDocument = createAsyncThunk(
  "stamp/cancel",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/delete-document`
      const user = JSON.parse(localStorage.getItem("userData"))
      await axios.delete(`${ENDPOINT}/${id}`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`
        }
      })
      await dispatch(AllStamping())
      await dispatch(AllTransfer())
      return id
    } catch (err) {
      return rejectWithValue(err.response.data)
      // console.log(err.response)
    }
  }
)

export const stampSlice = createSlice({
  name: "stamping",
  initialState: {
    allData: [],
    nostamp: [],
    draft: {},
    last: [],
    error: ""
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AllStamping.fulfilled, (state, action) => {
      state.allData = action.payload.all
      state.nostamp = action.payload.nostamp
      state.draft = {}
    })
    builder.addCase(stampDraft.fulfilled, (state, action) => {
      state.draft = action.payload
    })
    builder.addCase(lastDocument.fulfilled, (state, action) => {
      state.last = action.payload
    })
  }
})

export default stampSlice.reducer
