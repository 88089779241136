// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@configs/axios"
const entity = "Folder"

export const getAllFolder = createAsyncThunk("file-manager/view", async (type) => {
  const user = JSON.parse(localStorage.getItem("userData"))
  let ENDPOINT
  if (type) {
    ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/file-manager?dropdown=true`
  } else {
    ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/file-manager`
  }
  const response = await axios.get(ENDPOINT, {
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
  })
  return response.data.data
})

export const addFolder = createAsyncThunk(
  "file-manager/add",
  async (data, { dispatch, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"))
    const ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/file-manager`
    try {
      await axios.post(ENDPOINT, data, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
      await dispatch(getAllFolder())
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const folderSlice = createSlice({
  name: "folder",
  initialState: {
    folder: [],
    error: "",
    success: "",
    loading_form: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllFolder.fulfilled, (state, action) => {
      state.folder = action.payload
    })
    builder.addCase(addFolder.rejected, (state, action) => {
      state.error = action.payload.errors.status
      state.loading_form = false
      state.success = ""
    })
    builder.addCase(addFolder.pending, (state, action) => {
      state.loading_form = true
    })
    builder.addCase(addFolder.fulfilled, (state, action) => {
      state.loading_form = false
      state.error = ""
      state.success = `Berhasil tambah data ${entity}`
    })
  },
})

export default folderSlice.reducer
