// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@configs/axios"

export const getAllTickets = createAsyncThunk("ticket/viewall", async () => {
  const user = JSON.parse(localStorage.getItem("userData"))
  const url = `api/tickets`
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
  })
  const result = response.data
  return result
})

export const addTicket = createAsyncThunk(
  "ticket/addnew",
  async (data, { dispatch }) => {
    const user = JSON.parse(localStorage.getItem("userData"))
    const ENDPOINT = `/api/tickets`
    await axios.post(ENDPOINT, data, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    })
    await dispatch(getAllTickets())
    return data
  }
)

export const replyTicket = createAsyncThunk(
  "ticket/reply",
  async (data, { dispatch }) => {
    const user = JSON.parse(localStorage.getItem("userData"))
    const ENDPOINT = `/api/tickets/reply`
    await axios.post(ENDPOINT, data, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    })
    await dispatch(getAllTickets())
    return data
  }
)
export const reOpenTicket = createAsyncThunk(
  "ticket/reopen",
  async (data, { dispatch }) => {
    const user = JSON.parse(localStorage.getItem("userData"))
    const ENDPOINT = `/api/tickets/re-open`
    await axios.post(ENDPOINT, data, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    await dispatch(getAllTickets())
    return data
  }
)

export const ticketSlice = createSlice({
  name: "ticket",
  initialState: {
    allData: [],
    loading: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllTickets.fulfilled, (state, action) => {
      state.allData = action.payload.data
      state.loading = false
    })
  },
})

export default ticketSlice.reducer
