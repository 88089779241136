import React from "react"
import logo from "@src/assets/images/logo/digitax_logo.png"
import { ArrowRight } from "react-feather"
import { Link, useNavigate } from "react-router-dom"
import { SECONDARY } from "@configs/constants"
import { PRIMARY } from "../../../configs/constants"

const Bottom = () => {
  const navigate = useNavigate()
  const data = {
    alamat: `Telkom Landmark Tower 2, 41st Floor <br/>Jl. Jendral Gatot Subroto Kav. 52 Jakarta Selatan 12710 Indonesia`,
    email: `info@digitax.co.id`,
    phone: `(021) 5210123`,
    whatsapp: `+6281219000129`
  }
  return (
    <footer className="tw-bg-gray-50 tw-w-full tw-py-20" id="footer">
      <div className="tw-max-w-7xl tw-mx-auto tw-px-5">
        <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-5 tw-gap-10">
          <div className="tw-justify-self-start">
            <Link to={'/'}>
              <img
                src={logo}
                className="tw-object-contain tw-w-full tw-h-14 md:tw-h-14"
              />
            </Link>
          </div>
          <div className="tw-col-span-1">
            <div className="tw-text-2xl tw-font-bold">Alamat</div>
            <div className="tw-text-2xl tw-font-bold">The Telkom Hub</div>
            <p
              className="tw-pt-5 tw-text-lg"
              dangerouslySetInnerHTML={{ __html: data.alamat }}
            ></p>
          </div>
          <div className="tw-col-span-1">
            <div className="tw-text-2xl tw-font-bold">Bantuan dan Panduan</div>
            <div className="tw-pt-5 tw-text-lg tw-mb-5">
              <div>
                <Link to={'/terms-and-conditions'}>
                  Syarat dan Ketentuan
                </Link>
              </div>
              <div>
                <Link to={'/privacy-policy'}>
                  Kebijakan Privasi
                </Link>
              </div>
              <div>
                <Link to={'/return-policy'}>
                  Kebijakan Pengembalian Kuota eMeterai
                </Link>
              </div>
            </div>
          </div>
          <div className="tw-col-span-2">
            <div className="tw-text-2xl tw-font-bold">Hubungi Kami</div>
            <div className="tw-pt-5 tw-text-lg tw-mb-5">
              <div>Email : {data.email}</div>
              <div>Telp : {data.phone}</div>
            </div>
            <a href={`https://wa.me/${data.whatsapp}`} target={`_blank`}>
              <button
                className="tw-flex tw-flex-row tw-space-x-2 tw-items-center tw-px-10 text-white tw-py-3"
                style={{ borderRadius: "20px", background: PRIMARY }}
              >
                <span className="tw-text-2xl">Contact us</span>
                <ArrowRight />
              </button>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Bottom
