// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@configs/axios"
import { url } from "@utils"

export const HistoryReferral = createAsyncThunk(
  "referral/history",
  async () => {
    const user = JSON.parse(localStorage.getItem("userData"))
    const response = await axios.get(`${url.referral.history}`, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`
      }
    })
    const result = response.data
    return result
  }
)

export const HistoryRedeem = createAsyncThunk(
  "referral/redeem/history",
  async () => {
    const user = JSON.parse(localStorage.getItem("userData"))
    const response = await axios.post(`${url.referral.redeemHistory}`, {}, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`
      }
    })
    const result = response.data
    return result
  }
)

export const referralSlice = createSlice({
  name: "referral",
  initialState: {
    historyReferral: [],
    historyRedeem: [],
    check: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(HistoryReferral.fulfilled, (state, action) => {
      state.historyReferral = action.payload.data
      state.check = action.payload.check
    })
    builder.addCase(HistoryRedeem.fulfilled, (state, action) => {
      state.historyRedeem = action.payload.data
    })
  }
})

export default referralSlice.reducer
