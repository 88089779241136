// ** React Imports
import { lazy } from "react"
import { Navigate } from "react-router-dom"

const User = lazy(() => import("../../views/emeterai/master/user/list"))
const UserPersonal = lazy(() =>
  import("../../views/emeterai/master/user-personal/list")
)
// const Role = lazy(() => import("../views/emeterai/master/role"))
// const Permission = lazy(() => import("../views/emeterai/master/permission"))
const Department = lazy(() =>
  import("../../views/emeterai/master/department/list")
)
const Esign = lazy(() => import("../../views/emeterai/master/esign/list"))
const SingleTemplate = lazy(() =>
  import("../../views/emeterai/master/template")
)
const EditTemplate = lazy(() =>
  import("../../views/emeterai/master/template/edit-template")
)

const MasterRoutes = [
  {
    element: <User />,
    path: "/master/user"
  },
  {
    element: <UserPersonal />,
    path: "/master/personal-user"
  },
  // {
  //   element: <Role />,
  //   path: "/master/role"
  // },
  // {
  //   element: <Permission />,
  //   path: "/master/permission"
  // },
  {
    element: <Department />,
    path: "/master/department"
  },
  {
    element: <Esign />,
    path: "/master/esign"
  },
  {
    path: "/master/document-template",
    element: <SingleTemplate />,
    meta: {
      appLayout: true,
      className: "email-application"
    }
  },
  {
    path: "/master/edit-document-template/:id",
    element: <EditTemplate />
  }
]

export default MasterRoutes
