import { lazy } from "react"

const Account = lazy(() => import("../../views/emeterai/account"))

const AccountRoutes = [
  {
    path: "/account/:id",
    element: <Account />
  }
]

export default AccountRoutes
