// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@configs/axios"

export const CheckQuota = createAsyncThunk("quota/check", async () => {
  const user = JSON.parse(localStorage.getItem("userData"))
  let ENDPOINT = ``
  if (user.role == "admin") {
    ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/stock/quota-company`
  } else if (user.role == "user") {
    ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/stock/quota-departement/${user.departement_id}`
  } else {
    ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/stock/quota-user`
  }
  const response = await axios.get(`${ENDPOINT}`, {
    headers: {
      Authorization: `Bearer ${user.accessToken}`
    }
  })
  if (user.role == "admin") {
    return response.data.data.totalCompanyStock
  } else if (user.role == "user") {
    return response.data.data.departementTotalStock
  } else {
    return response.data.data.stockTotalUser
  }
})

export const CheckResponse = createAsyncThunk("peruri/check", async () => {
  const user = JSON.parse(localStorage.getItem("userData"))
  const ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/check`
  const response = await axios.get(`${ENDPOINT}`, {
    headers: {
      Authorization: `Bearer ${user.accessToken}`
    }
  })
  return response.data.data
})

export const qoutaLeftSlice = createSlice({
  name: "checkQuota",
  initialState: {
    stock: 0,
    peruriResponseTime: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(CheckQuota.fulfilled, (state, action) => {
      state.stock = action.payload
    })
    builder.addCase(CheckResponse.fulfilled, (state, action) => {
      state.peruriResponseTime = action.payload
    })
    builder.addCase(CheckResponse.rejected, (state, action) => {
      state.peruriResponseTime = 15
    })
    builder.addCase(CheckResponse.pending, (state, action) => {
      state.peruriResponseTime = -1
    })
  }
})

export default qoutaLeftSlice.reducer
