// ** React Imports
import { lazy } from "react"

const Stamp = lazy(() => import("../../views/emeterai/third-party/services/stamp"))
const Dummy = lazy(() => import("../../views/emeterai/third-party/services/dummy"))

const ThirdPartyRoutes = [
  {
    element: <Stamp />,
    path: "/services/stamp",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false
    }
  },
  {
    element: <Dummy />,
    path: "/services/dummy",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false
    }
  },
]

export default ThirdPartyRoutes
