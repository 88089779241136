// ** Icons Import
import { Home, Activity, ShoppingCart, FileText } from "react-feather"

export default [
  {
    id: "stamp",
    title: "My e-Document",
    icon: <FileText size={14} />,
    navLink: "/stamp",
    // children: [
    //   {
    //     id: "singleStamp",
    //     title: "Single Stamp",
    //     icon: <Activity />,
    //     navLink: "/stamp"
    //   }
    // ]
  }
]
