import { lazy } from "react"
const Purchase = lazy(() => import("../../views/emeterai/quota/purchase"))
const PaymentConfirm = lazy(() =>
  import("../../views/emeterai/quota/purchase/payment-confirm")
)
const Transfer = lazy(() => import("../../views/emeterai/quota/transfer"))
const Invoice = lazy(() => import("../../views/emeterai/invoice"))
const InvoicePlan = lazy(() => import("../../views/emeterai/invoice/plan"))
const InvoicePayment = lazy(() =>
  import("../../views/emeterai/invoice/payment")
)
const InvoicePrint = lazy(() => import("../../views/emeterai/invoice/PrintPDF"))
const InvoicePrintPlan = lazy(() =>
  import("../../views/emeterai/invoice/plan/PrintPDF")
)

const QuotaRoutes = [
  {
    path: "/quota/purchase",
    element: <Purchase />
  },
  {
    path: "/quota/transfer",
    element: <Transfer />
  },
  {
    element: <Invoice />,
    path: "/quota/invoice/:id"
  },
  {
    element: <InvoicePlan />,
    path: "/plan/invoice/:id"
  },
  {
    path: "/plan/invoice/print/:id",
    element: <InvoicePrintPlan />,
    meta: {
      layout: "blank"
    }
  },
  {
    element: <PaymentConfirm />,
    path: "/quota/payment-confirm/:id"
  },
  {
    element: <InvoicePayment />,
    path: "/quota/payment-receipt/:id"
  },
  {
    path: "/quota/invoice/print/:id",
    element: <InvoicePrint />,
    meta: {
      layout: "blank"
    }
  }
]

export default QuotaRoutes
