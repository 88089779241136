// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@configs/axios"
const ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/esign`
const entity = "Esign"

export const AllEsign = createAsyncThunk("master/esign", async () => {
  const user = JSON.parse(localStorage.getItem("userData"))
  const endpoint = `/api/${process.env.REACT_APP_API_VERSION}/esign/by/${user.id}`
  const response = await axios.get(endpoint, {
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
  })
  return response.data.data[0].esign
})

export const addEsign = createAsyncThunk(
  "esign/add",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = JSON.parse(localStorage.getItem("userData"))
      await axios.post(ENDPOINT, data, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      await dispatch(AllEsign())
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const deleteEsign = createAsyncThunk(
  "esign/delete",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const user = JSON.parse(localStorage.getItem("userData"))
      await axios.delete(`${ENDPOINT}/${id}`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
      await dispatch(AllEsign())
      return id
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const updateEsign = createAsyncThunk(
  "esign/update",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = JSON.parse(localStorage.getItem("userData"))
      const res = await axios.patch(`${ENDPOINT}/${data.id}`, data, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      await dispatch(AllEsign())
      return res
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const esignSlice = createSlice({
  name: "esign",
  initialState: {
    allData: [],
    loading: true,
    loading_form: true,
    error: "",
    success: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AllEsign.fulfilled, (state, action) => {
      state.allData = action.payload
      state.loading = false
      state.success = ``
      state.error = ``
    })
    builder.addCase(addEsign.rejected, (state, action) => {
      state.error = action.payload.errors.status
      state.loading_form = false
      state.success = ""
    })
    builder.addCase(addEsign.pending, (state, action) => {
      state.loading_form = true
    })
    builder.addCase(addEsign.fulfilled, (state, action) => {
      state.loading_form = false
      state.error = ""
      state.success = `Berhasil tambah data ${entity}`
    })
    builder.addCase(updateEsign.rejected, (state, action) => {
      state.loading_form = false
      state.error = action.payload.errors.status
      state.success = ""
    })
    builder.addCase(updateEsign.fulfilled, (state, action) => {
      state.loading_form = false
      state.error = ""
      state.success = `Berhasil update data ${entity}`
    })
    builder.addCase(deleteEsign.rejected, (state, action) => {
      state.loading_form = false
      state.error = action.payload.errors.status
      state.success = ""
    })
    builder.addCase(deleteEsign.fulfilled, (state, action) => {
      state.loading_form = false
      state.error = ""
      state.success = `Berhasil hapus data ${entity}`
    })
  },
})

export default esignSlice.reducer
