// ** React Imports
import { Outlet } from "react-router-dom"

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from "@layouts/VerticalLayout"

// ** Menu Items Array
// import navigation from "@src/navigation/horizontal"
import personalNoPlan from "@src/navigation/vertical/personal-no-plan"
import personalPlan1 from "@src/navigation/vertical/personal-plan-1"
import personalPlan2 from "@src/navigation/vertical/personal-plan-2"
import personalPlan2Child from "@src/navigation/vertical/personal-plan-2-child"
import adminCompany from "@src/navigation/vertical/admin-company/standard"
import adminCompanyPayu from "@src/navigation/vertical/admin-company/payu"
import admin from "@src/navigation/vertical/admin"
import userCompany from "@src/navigation/vertical/user-company"
import { useDispatch, useSelector } from "react-redux"
import { getSubs } from "./store"
import { useEffect } from "react"
// import navigation from "@src/navigation/vertical"

const VerticalLayout = (props) => {
  const user = JSON.parse(localStorage.getItem("userData"))
  const plan = useSelector((store) => store.plan)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getSubs())
  }, [])

  const menuChooser = () => {
    if (user?.role == "admin") {
      return adminCompany
    }
    if (user?.role == "user") {
      return userCompany
    }
    if (user?.role == "admin-company") {
      if (user.user_type == 'payu') {
        return adminCompanyPayu
      } else {
        return admin
      }
    }
    if (user?.user_type) {
      if (plan.is_childUser) {
        if (plan.is_parent == 0) {
          return personalPlan2Child
        }
        return personalPlan2
      } else if (plan.plan) {
        return personalPlan1
      } else {
        return personalNoPlan
      }
    }
  }
  // const [menuData, setMenuData] = useState([])

  // ** For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])

  return (
    <Layout menuData={menuChooser()} {...props}>
      <Outlet />
    </Layout>
  )
}

export default VerticalLayout
