// ** React Imports
import { lazy } from "react"
import { Navigate } from "react-router-dom"

const FileManager = lazy(() =>
  import("../../views/emeterai/document/file-manager")
)

const DocumentRoutes = [
  {
    element: <FileManager />,
    path: "/document/file-manager"
  }
]

export default DocumentRoutes
