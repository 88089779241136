// ** Icons Import
import { Send, ShoppingBag, ShoppingCart } from "react-feather"

export default [
    // {
    //   header: "Quota",
    // },
  {
    id: "quota",
    title: "Quota",
    icon: <ShoppingCart size={14} />,
    children: [
      {
        id: "quotaPurchase",
        title: "Purchase",
        icon: <ShoppingBag size={14} />,
        navLink: "/quota/purchase",
      },
      {
        id: "quotaTransfer",
        title: "Transfer",
        icon: <Send size={14} />,
        navLink: "/quota/transfer",
      },
    ],
  },
]
