import mock from "../mock"

// ** Utils
import { paginateArray } from "../utils"

const data = {
  stamping: [
    {
      id: 1,
      no: "PAKTA-INTEGRITAS/NIK/836001",
      nama: "Pakta Integritas - Endang Suherman.pdf",
      tipe: "Dokumen Lain-Lain",
      tanggal: "2022-11-10",
      tanggalInput: "2022-11-10",
      tanggalDibubuhkan: "2022-11-10",
      status: "Berhasil",
      serialNumber: "QIEZ1431ZV0GJVCQ0000A9",
      appliedBy: "FINANCE",
    },
    {
      id: 2,
      no: "PAKTA-INTEGRITAS/NIK/NNNNNN",
      nama: "Pakta.pdf",
      tipe: "Dokumen Lain-Lain",
      tanggal: "2022-11-10",
      tanggalInput: "2022-11-10",
      tanggalDibubuhkan: "2022-11-10",
      status: "Pending",
      serialNumber: "67IUBNJQ6P0GIFFU0000A9",
      appliedBy: "HR",
    },
  ],
}

// GET ALL DATA
mock.onGet("/api/stamping/list/all-data").reply(200, data.stamping)
