// ** React Imports
import { Fragment } from "react"

// ** Custom Components
import NavbarUser from "./NavbarUser"
import NavbarBookmarks from "./NavbarBookmarks"
import logo from "@src/assets/images/logo/digitax_logo.png"
import { Link } from "react-router-dom"
import "./style.css"

const ThemeNavbar = (props) => {
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props

  return (
    <Fragment>
      <div className="bookmark-wrapper d-flex align-items-center">
        <NavbarBookmarks setMenuVisibility={setMenuVisibility} />
        <ul className="nav navbar-nav">
          <li className="nav-item">
            <Link to="/" className="navbar-brand">
              <img className="logo" id="logo" src={logo} />              
            </Link>
          </li>
        </ul>
      </div>
      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  )
}

export default ThemeNavbar
