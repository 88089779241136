// ** Reducers Imports
import navbar from "./navbar"
import layout from "./layout"
import auth from "./authentication"

// import todo from "@src/views/apps/todo/store"
// import chat from "@src/views/apps/chat/store"
// import users from "@src/views/apps/user/store"
// import email from "@src/views/apps/email/store"
// import kanban from "@src/views/apps/kanban/store"
// import invoice from "@src/views/apps/invoice/store"
// import calendar from "@src/views/apps/calendar/store"
// import ecommerce from "@src/views/apps/ecommerce/store"
// import dataTables from "@src/views/tables/data-tables/store"
// import permissions from "@src/views/apps/roles-permissions/store"
// import reportStamping from "@src/views/report/stamping/store"

import user from "@src/views/emeterai/master/user/store"
import userPersonal from "@src/views/emeterai/master/user-personal/store"
import department from "@src/views/emeterai/master/department/store"
// import register from "@src/views/emeterai/authentication/register/store"
import register from "@store/store/register"
import referral from "@store/store/referral"
import ticket from "@src/views/emeterai/support/ticket-support/store"
import detailTicket from "@src/views/emeterai/support/ticket/detail/store"
import transfer from "@src/views/emeterai/quota/transfer/store"
import purchase from "@src/views/emeterai/quota/purchase/store"
import notification from "@src/views/emeterai/account/store"
import esign from "@src/views/emeterai/master/esign/store"
import templates from "@src/views/emeterai/stamp/bulk/store"
import batch from "@src/views/emeterai/stamp/bulk/data/store"
import detailBatch from "@src/views/emeterai/stamp/bulk/detail/store"
import stamp from "@src/views/emeterai/stamp/single/data/store"
import quotaLeft from "@layouts/components/navbar/store"
import folder from "@src/views/emeterai/document/store"
import plan from "@src/layouts/store"
import transaction from "@src/views/emeterai/plans/store"
import config from "@src/views/emeterai/dashboard/store"
import digitalLocker from "@store/store/digitalLocker.js"

import transferPersonal from "@store/store/transferPersonal"

const rootReducer = {
  auth,
  navbar,
  layout,
  department,
  register,
  ticket,
  detailTicket,
  transfer,
  purchase,
  notification,
  esign,
  batch,
  user,
  stamp,
  quotaLeft,
  folder,
  templates,
  detailBatch,
  plan,
  transaction,
  userPersonal,
  config,

  referral,
  transferPersonal,
  digitalLocker
  // todo,
  // chat,
  // email,
  // users,
  // kanban,
  // invoice,
  // calendar,
  // ecommerce,
  // dataTables,
  // permissions,
  // reportStamping,
}

export default rootReducer
