import mock from "../mock"

// ** Utils
import { paginateArray } from "../utils"

const data = {
  tickets: [
    {
      id: 1,
      fullName: "Galen Slixby",
      type: "Technical Issue",
      status: "On Progress",
      desc: "Tidak bisa stamping single",
      level: "High",
    },
    {
      id: 2,
      fullName: "Galen Slixby",
      type: "Technical Issue",
      status: "Close",
      desc: "Tidak bisa stamping single",
      level: "Medium",
    },
    {
      id: 3,
      fullName: "Galen Slixby",
      type: "Technical Issue",
      status: "Pending",
      desc: "Tidak bisa stamping single",
      level: "Low",
    },
  ],
}

// GET ALL DATA
mock.onGet("/api/tickets/list/all-data").reply(200, data.tickets)
