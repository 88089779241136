import mock from "../mock"

// ** Utils
import { paginateArray } from "../utils"

const data = {
  stamping: [
    {
      id: 1,
      nama: "Esign Dummy",
      image: require("@src/assets/images/logo/logo-sm.png").default,
      tanggal: "2022-11-10",
    },
    {
      id: 2,
      nama: "Esign Dummy (2)",
      image: require("@src/assets/images/logo/favicon.png").default,
      tanggal: "2022-11-10",
    },
  ],
}

// GET ALL DATA
mock.onGet("/api/e-sign/list/all-data").reply(200, data.stamping)
