import React, { useEffect, useState } from 'react'
import { Copy, X } from 'react-feather'
import toast from "react-hot-toast"

const ToastReferralCoded = ({t}) => {
    return (
      <div className="d-flex">
        <div className="me-1">
          {/* <Avatar size="sm" color="success" icon={<Coffee size={12} />} /> */}
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center">
            <span className='fw-bolder'>Kode DigiFriend berhasil disalin! </span>
            <X
              size={12}
              className="cursor-pointer ms-1"
              onClick={() => toast.dismiss(t.id)}
            />
          </div>
          <span>
            
          </span>
        </div>
      </div>
    )
}


const DigiFriend = ({user}) => {
    const [refCode, setRefCode] = useState('')
    useEffect(() => {
      if (user) {
        setRefCode(user.emet_id)
      }
    }, [user])

    // const referral_code = user?.emet_id ? user.emet_id : 'ABC123'
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(refCode)
            toast.success((t) => 
            (
                <ToastReferralCoded
                t={t}
                />
            ), {
                position: 'top-center'
            })
        } catch (err) {
            console.error(
                "Unable to copy to clipboard.",
                err
            )
            alert("Copy to clipboard failed.")
        }
    
    }


  return (
    <div className='digifriend-label'>
        <small>DigiFriend Code :</small>

        <div className='d-flex align-items-center ms-25'>
            <span className='fw-bolder fs-4'>{refCode}</span>
            <Copy className='ms-1 cursor-pointer' size={40} color='#333' onClick={handleCopy} />
        </div> 
    </div>
  )
}

export default DigiFriend