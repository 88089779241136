// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@configs/axios"
import { url } from "@utils"

export const HistoryTransfer = createAsyncThunk(
  "transfer-personal/history",
  async () => {
    const user = JSON.parse(localStorage.getItem("userData"))
    try {
      const response = await axios.post(`${url.transfer.history}`, 
      {},
      {
        headers: {
          Authorization: `Bearer ${user.accessToken}`
        }
      })
      const result = response.data
      return result 
    } catch (error) {
      console.log(error)
    }
  }
)

export const SubmitTransfer = createAsyncThunk(
  "transfer-personal/submit",
  async (payload, { dispatch, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"))
    try {
      const response = await axios.post(`${url.transfer.submit}`, 
      payload,
      {
        headers: {
          Authorization: `Bearer ${user.accessToken}`
        }
      })
      await dispatch(HistoryTransfer())
      return response 
    } catch (error) {
      console.log(error)
      return rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const transferPersonalSlice = createSlice({
  name: "transferPersonal",
  initialState: {
    historyTransfer: [],
    loadingTransfer: false,
    statusTransferError: false,
    messageTransferError: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(HistoryTransfer.fulfilled, (state, action) => {
      state.historyTransfer = action.payload.data
      state.loadingTransfer = false
    })
    builder.addCase(HistoryTransfer.pending, (state, action) => {
        state.loadingTransfer = true
    })
    builder.addCase(HistoryTransfer.rejected, (state, action) => {
        state.loadingTransfer = false
    })
    builder.addCase(SubmitTransfer.fulfilled, (state, action) => {
      state.statusTransferError = false
      state.messageTransferError = ''
    })
    builder.addCase(SubmitTransfer.pending, (state, action) => {
      state.statusTransferError = false
      state.messageTransferError = '' 
    })
    builder.addCase(SubmitTransfer.rejected, (state, action) => {
      console.log(action)
      state.statusTransferError = true
      state.messageTransferError = action.payload
    })
  }
})

export default transferPersonalSlice.reducer
