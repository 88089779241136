// ** React Imports
import { lazy } from "react"

const LandingPage = lazy(() => import("../../views/landing-page"))
const Privacy = lazy(() => import("../../views/landing-page/EULA/Privacy"))
const Terms = lazy(() => import("../../views/landing-page/EULA/Terms"))
const Retur = lazy(() => import("../../views/landing-page/EULA/Retur"))

const LandingPageRoutes = [
  {
    element: <LandingPage />,
    path: "/",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false
    }
  },
  {
    element: <Privacy />,
    path: "/privacy-policy",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false
    }
  },
  {
    element: <Retur />,
    path: "/return-policy",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false
    }
  },
  {
    element: <Terms />,
    path: "/terms-and-conditions",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false
    }
  }
]

export default LandingPageRoutes
