export const WIDTH_EMET = 75
export const HEIGHT_EMET = 75
export const PRIMARY = "#00176a"
export const SECONDARY = "#6c757d"
export const POINT_REFERRAL_PER_USER = 10
export const userRoles = [
  {
    index: 1,
    role: "admin"
  },
  {
    index: 2,
    role: "user"
  },
  {
    index: 3,
    role: "super"
  },
  {
    index: 4,
    role: "admin-company"
  }
]
