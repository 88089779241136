// ** React Imports
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

// ** Custom Components
import Avatar from "@components/avatar"

// ** Utils
import { isUserLoggedIn } from "@utils"

// ** Store & Actions
import { useDispatch } from "react-redux"
import { handleLogout } from "@store/authentication"
import EmeteraiPlaceholder from "@src/assets/images/logo/e-materai.png"

// ** Third Party Components
import {
  User,
  Mail,
  CheckSquare,
  MessageSquare,
  Settings,
  CreditCard,
  HelpCircle,
  Power
} from "react-feather"

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
  Card
} from "reactstrap"

// ** Default Avatar Image
import defaultAvatar from "@src/assets/images/profile/profile.png"
import DigiFriend from "./DigiFriend"

const UserDropdown = ({ store, plan }) => {
  // ** Store Vars
  const dispatch = useDispatch()
  // ** State
  const [userData, setUserData] = useState(null)
  const user = JSON.parse(localStorage.getItem("userData"))

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem("userData")))
    }
  }, [])

  useEffect(() => {}, [])

  //** Vars
  const userAvatar = (userData && userData.avatar) || defaultAvatar

  return userData ? (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item ms-1">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        {/* <div className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">
            {userData?.company_name && userData.company_name}
            {userData?.user_type == "personal" && userData.fullName}
          </span>
          <span className="user-status">
            <span className="fw-bolder">
              {(userData && userData.role) || "Personal"}{" "}
            </span>
            {userData?.departement_name && `(${userData?.departement_name})`}
          </span>
        </div> */}
        <Avatar
          img={
            user?.avatar != null
              ? `${process.env.REACT_APP_API_ENDPOINT}/files/${user?.avatar}`
              : defaultAvatar
          }
          imgHeight="40"
          imgWidth="40"
          status="online"
        />
      </DropdownToggle>
      <DropdownMenu end style={{ minWidth: "200px" }}>
        {/* <DropdownItem> */}
        {userData?.user_type == "personal" ? (
          <div className="d-flex flex-column p-1">
            <div className="d-flex align-items-center justify-content-between">
              <span className="align-middle text-left fw-bolder text-uppercase">
                {userData?.fullName}
              </span>
              <span
                className="fw-bolder"
                style={{ fontSize: "12px"}}
              >
                (Personal)
              </span>
            </div>
            <DigiFriend user={userData} />
          </div>
        ) : (
          <div className="d-flex flex-column p-1">
            <span className="align-middle text-left fw-bolder text-uppercase text-black">
              {userData?.fullName}
            </span>
            <div
              className="text-black d-flex flex-column"
              style={{ marginTop: "5px", paddingTop: "10px" }}
            >
              <span
                className="align-middle text-left fw-bold"
                style={{ fontSize: "12px", marginBottom: "5px" }}
              >
                {userData?.company_name}
              </span>
              <span
                className="align-middle text-left fw-bold"
                style={{ fontSize: "10px", marginBottom: "5px" }}
              >
                {userData?.departement_name}
              </span>
              <span
                className="align-middle text-left fw-bolder"
                style={{ fontSize: "10px" }}
              >
                ({userData?.role})
              </span>
            </div>

            <DigiFriend user={userData} />
          </div>
        )}
        {/* </DropdownItem> */}
        {
          user.user_type != 'payu' && (
            <>
            <DropdownItem divider />
            {/* <DropdownItem disabled> */}
              <div className="p-1">
                <div className="d-flex flex-row">
                  <Card
                    color="primary"
                    // size="sm"
                    // outline
                    // block
                    inverse
                    className="p-2 d-flex flex-column w-full align-items-center justify-content-between"
                    style={{ minWidth: "120px", marginBottom: 0 }}
                  >
                    {/* <img
                      src={EmeteraiPlaceholder}
                      width={20}
                      height={20}
                      className="me-1"
                    /> */}
                    <span className="fs-3 fw-bolder mb-50">
                      {user?.role == "admin"
                        ? store.quotaDepartmentAdmin
                        : store.unallocated}
                    </span>
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      eMeterai
                    </span>
                  </Card>
                  <Card
                    // color="primary"
                    size="sm"
                    outline
                    // block
                    inverse
                    className="p-2 d-flex flex-column w-full align-items-center justify-content-between ms-50 !mb-0"
                    style={{ minWidth: "120px", marginBottom: 0 }}
                  >
                    <span className="text-primary fs-3 fw-bolder mb-50">
                      {/* {store.esign ? store.esign : 0} */}
                      {plan?.total_esign
                        ? user?.company_id == 1
                          ? `∞`
                          : plan?.total_esign
                        : 0}
                    </span>
                    <span className="text-primary" style={{ fontSize: "12px", fontWeight: "bold" }}>
                      eSign
                    </span>
                  </Card>
                </div>
              {/* </DropdownItem> */}
              {/* <DropdownItem disabled> */}
                <div className="d-flex flex-row pt-50">
                  {plan?.total_psre > 0 || user?.company_id == 1 ? (
                    <Card
                      size="sm"
                      outline
                      inverse
                      className="p-2 d-flex flex-column w-full align-items-center justify-content-between"
                      style={{ minWidth: "120px", marginBottom: 0 }}
                    >
                      <span className="text-primary fs-3 fw-bolder mb-50">
                        {plan?.total_psre > 999 ? `∞` : plan?.total_psre ? plan?.total_psre : 0}
                      </span>
                      <span className="text-primary" style={{ fontSize: "12px", fontWeight: "bold" }}>
                        PSrE
                      </span>
                    </Card>
                  ) : null}
                  {plan?.total_childUser > 0 && (
                    <Card
                      size="sm"
                      outline
                      inverse
                      className="p-2 d-flex flex-column w-full align-items-center justify-content-between ms-50"
                      style={{ minWidth: "120px", marginBottom: 0 }}
                    >
                      <span className="fs-3 fw-bolder mb-50 text-primary">
                        {plan?.total_childUser ? plan?.total_childUser : 0}
                      </span>
                      <span className="text-primary" style={{ fontSize: "12px", fontWeight: "bold" }}>
                        User
                      </span>
                    </Card>
                  )}
                </div>
              {/* </DropdownItem> */}
              </div>
            <DropdownItem divider />
            </>
          )
        }
        <DropdownItem tag={Link} to="/account/profile">
          <User size={14} className="me-75" />
          <span className="align-middle">Account</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/setting/security">
          <Settings size={14} className="me-75" />
          <span className="align-middle">Setting</span>
        </DropdownItem>
        {/* <DropdownItem tag={Link} to="/account/security">
          <Settings size={14} className="me-75" />
          <span className="align-middle">Settings</span>
        </DropdownItem> */}
        <DropdownItem
          tag={Link}
          to="/login"
          onClick={() => dispatch(handleLogout())}
        >
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  ) : null
}

export default UserDropdown
