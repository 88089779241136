// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@configs/axios"
import { url } from '@utils'

const entity = 'Template'

export const AllTemplate = createAsyncThunk(
  "template/bulk",
  async () => {
    // const url = `/api/template-esign`
    const user = JSON.parse(localStorage.getItem("userData"))
    const response = await axios.get(`${url.listTemplate}`, {
      // const response = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    const result = response.data.data
    return {
      all: result,
    }
  }
)

export const deleteTemplate = createAsyncThunk(
  "template/delete",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/template/deleted`
      // const ENDPOINT = `/api/template-esign`
      const user = JSON.parse(localStorage.getItem("userData"))
      await axios.delete(`${ENDPOINT}/${id}`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
      await dispatch(AllTemplate())
      return id
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const updateTemplate = createAsyncThunk(
  "template/update",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      // const ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/template`
      const ENDPOINT = `/api/template-esign/update`
      const user = JSON.parse(localStorage.getItem("userData"))
      const res = await axios.patch(`${ENDPOINT}/${data.id}`, data, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
      await dispatch(AllTemplate())
      return res
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const templateSlice = createSlice({
  name: "template",
  initialState: {
    allData: [],
    loading: true,
    loading_form: true,
    error: "",
    success: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AllTemplate.fulfilled, (state, action) => {
      state.allData = action.payload.all
      state.loading = false
      state.loading_form = false
      state.error = ""
    })
    builder.addCase(updateTemplate.rejected, (state, action) => {
      state.loading_form = false
      state.error = action.payload.errors.status
      state.success = ""
    })
    builder.addCase(updateTemplate.fulfilled, (state, action) => {
      state.loading_form = false
      state.error = ""
      state.success = `Berhasil update data ${entity}`
    })
    builder.addCase(deleteTemplate.rejected, (state, action) => {
      state.loading_form = false
      state.error = action.payload.errors.status
      state.success = ""
    })
    builder.addCase(deleteTemplate.fulfilled, (state, action) => {
      state.loading_form = false
      state.error = ""
      state.success = `Berhasil hapus data ${entity}`
    })
  },
})

export default templateSlice.reducer
