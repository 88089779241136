// ** Icons Import
import { File, Folder } from "react-feather"

export default [
  // {
  //   header: "Document",
  // },
  // {
  //   id: "document",
  //   title: "Document",
  //   icon: <Box />,
  //   children: [
  //   ],
  // },
  {
    id: "fileManager",
    title: "Digital Locker",
    icon: <Folder size={14} />,
    navLink: "/document/file-manager",
  },
]
