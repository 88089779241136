import mock from "../mock"

// ** Utils
import { paginateArray } from "../utils"

const data = {
  transfer: [
    {
      id: 1,
      header: "Payment sukses",
      content: "Pembayaran anda telah kami terima",
      date: "2022-12-01",
    },
    {
      id: 2,
      header: "Pembubuhan sukses",
      content: "Single stamping berhasil dilakukan pada 5 Desember 2022",
      date: "2022-12-01",
    },
    {
      id: 3,
      header: "Payment sukses",
      content: "Single stamping berhasil dilakukan pada 5 Desember 2022",
      date: "2022-12-01",
    },
  ],
}

// GET ALL DATA
mock.onGet("/api/notification/list/all-data").reply(200, data.transfer)
