import moment from "moment"
import { DefaultRoute } from "../router/routes"
import axios from "@configs/axios"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: "short", day: "numeric" }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" }
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData")
export const getUserData = () => JSON.parse(localStorage.getItem("userData"))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return DefaultRoute
  if (userRole === "user") return DefaultRoute
  return "/login"
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed" // for input hover border-color
  }
})

export const filterByValue = (array, string) => {
  return array.filter((o) =>
    Object.keys(o).some((k) =>
      o[k].toLowerCase().includes(string.toLowerCase())
    )
  )
}

export const formatDateTime = (value) => {
  // if (value) {
  //   const [yyyy, mm, dd, hh, mi] = value.split(/[/:\-T]/)
  //   return `${yyyy}-${mm}-${dd} ${hh}:${mi}`
  // }
  // return true
  return moment(value).format("YYYY-MM-DD HH:mm")
}
export const formatDateOnly = (value) => {
  // if (value) {
  //   const [yyyy, mm, dd, hh, mi] = value.split(/[/:\-T]/)
  //   return `${yyyy}-${mm}-${dd}`
  // }
  // return true
  return moment(value).format("YYYY-MM-DD")
}

export const formatter = new Intl.NumberFormat("id-ID", {
  style: "currency",
  currency: "IDR",
  maximumFractionDigits: 0
})

export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

export function secondsToTime(e) {
  const h = Math.floor(e / 3600)
    .toString()
    .padStart(2, "0")
  const m = Math.floor((e % 3600) / 60)
    .toString()
    .padStart(2, "0")
  const s = Math.floor(e % 60)
    .toString()
    .padStart(2, "0")

  // return h + ':' + m + ':' + s
  return `${m}:${s}`
  //return `${h}:${m}:${s}`;
}

export const progressSign = (data) => {
  let totalSign = 0
  const totalData = data.length
  for (let index = 0; index < data.length; index++) {
    const element = data[index]
    if (element.status == "draft") {
      totalSign++
    }
  }
  const percentage = Math.round((totalSign / totalData) * 100, 0)
  return {
    totalData,
    totalSign,
    percentage
  }
}

export const truncate = (str, max = 8) => {
  const array = str.trim().split(" ")
  const ellipsis = array.length > max ? "..." : ""

  return array.slice(0, max).join(" ") + ellipsis
}

export const convertToMB = (amount, type) => {
  let multiplier = 1000000
  if (type == 'KB') {
    multiplier = 1000 
  }
  return Math.round(amount / multiplier, 0)
}

const downloadDocument = async (document_id, document_file) => {
  const user = JSON.parse(localStorage.getItem('userData'))
  try {
    const res = await axios.get(
      `/api/v1/single-stamp/download-file/${document_id}`,
      {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
          "Content-Type": "application/pdf"
        },
        responseType: "arraybuffer"
      }
    )
    // window.open(
    //   URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }))
    // )
    const fileURL = URL.createObjectURL(
      new Blob([res.data], { type: "application/pdf" })
    )
    const fileLink = document.createElement("a")
    fileLink.href = fileURL
    fileLink.download = document_file
    fileLink.click()
    // return res
    // console.log("res ", res)
  } catch (error) {
    alert("Terjadi kesalahan, mohon coba beberapa saat lagi")
  }
}

const handleClickPSRE = (psre_path) => {
  window.open(`${psre_path}`, "_blank")
}

const handleClickEsignOnly = (file_path) => {
  window.open(
    `${process.env.REACT_APP_API_ENDPOINT}/UNSIGNED/${file_path}`,
    "_blank"
  )
}

export const handleDownload = async (e, mail) => {
  e.stopPropagation()
  if (mail.signing_method) {

    if (mail.serial_number_id) {
      // setModalPreview(true)
      // setSelectedData(mail)
      downloadDocument(mail?.document_id, mail?.document_file)
    } else {
      if (mail.psre_id) {
        handleClickPSRE(mail?.psre_path)
      } else {
        handleClickEsignOnly(mail?.document_file)
      }
    }

  } else {
    if (mail.serial_number_id) {
      if (mail.status == 'stamp') {
        downloadDocument(mail?.document_id, mail?.document_file)
      }
    } else {
      if (mail.psre_id) {
        handleClickPSRE(mail?.psre_id)
      }
      if (mail.signing_status == 'signing') {
        handleClickEsignOnly(mail?.document_file)
      }
    }
  }
}

export const handleDownloadFileManager = async (e, mail) => {
  console.log('mail ', mail)
  e.stopPropagation()
  if (mail.signing_method) {

    if (mail.serial_number_id) {
      // setModalPreview(true)
      // setSelectedData(mail)
      downloadDocument(mail?.details[0]?.document_id, mail?.details[0]?.document_file)
    } else {
      if (mail.psre_id) {
        handleClickPSRE(mail?.psre_path)
      } else {
        handleClickEsignOnly(mail?.file_path)  
      }
    }

  } else {
    if (mail.serial_number_id) {
      if (mail.status == 'stamp') {
        downloadDocument(mail?.details[0]?.document_id, mail?.details[0]?.document_file)
      }
    } else {
      if (mail.psre_id) {
        handleClickPSRE(mail?.details[0]?.psre_path)
      }
      if (mail.signing_status == 'signing') {
        handleClickEsignOnly(mail?.file_path)
      }
    }
  }
}

export const url = {
  uploadDocument: `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/upload-document`,
  uploadDocumentMultiSign: `/api/${process.env.REACT_APP_API_VERSION}/esign-only/upload-document`,
  esignOnly: `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/upload-document-only`,
  // uploadDocument: `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/upload-document`,
  updateDocument: `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/update-document`,
  embedEsign: `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/embed-esign`,
  downloadDocument: `/api/${process.env.REACT_APP_API_VERSION}/vida-psre/download-vida-docs`,
  stampSingle: `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/stamp-process`,
  updateEmet: `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/update-emet`,
  draftReviewer: `/api/${process.env.REACT_APP_API_VERSION}/stamp/esign-coordinates`,
  // updateCoordinateStamp: `/api/${process.env.REACT_APP_API_VERSION}/stamp/update-stamp-coordinates`,
  updateCoordinateEsign: `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/update-signer-data`,
  updateCoordinateMultiEsign: `/api/${process.env.REACT_APP_API_VERSION}/esign-only/update-signer-data`,
  updateCoordinateStamp: `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/update-document`,
  // updateCoordinateEsign: `/api/${process.env.REACT_APP_API_VERSION}/stamp/esign-coordinates`,
  updateCoordinateEsignApprover: `/api/${process.env.REACT_APP_API_VERSION}/stamp/esign-coordinates-approver`,
  // listStamp: `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/get-list-documents`,
  listStamp: `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/get-document-user`,
  oneTimeProcess: `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/stamp-single-process-company`,
  oneTimeProcessPSRE: `/api/${process.env.REACT_APP_API_VERSION}/vida-psre/stamp-process-company-psre`,
  oneTimeProcessPersonal: `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/stamp-single-process-personal`,
  createTemplate: `/api/${process.env.REACT_APP_API_VERSION}/template/create-template`,
  updateTemplate: `/api/${process.env.REACT_APP_API_VERSION}/template/update-template`,
  // listTemplate: `/api/${process.env.REACT_APP_API_VERSION}/template/get-templates`,
  listTemplate: `/api/${process.env.REACT_APP_API_VERSION}/template`,
  uploadBatch: `/api/${process.env.REACT_APP_API_VERSION}/batch-stamp/batch-document-upload`,
  stampBatch: `/api/${process.env.REACT_APP_API_VERSION}/batch-stamp/stamp-batch-process`,
  listBatch: `/api/${process.env.REACT_APP_API_VERSION}/batch-stamp/get-batch-documents`,
  detailBatch: `/api/${process.env.REACT_APP_API_VERSION}/batch-stamp/detail-batch-documents`,
  oneProcessStampBatch: `/api/${process.env.REACT_APP_API_VERSION}/batch-stamp/batch-document-upload-one-process`,
  oneProcess: `/api/${process.env.REACT_APP_API_VERSION}/stamp/one-stamp-process`,
  applyTokenPayment: `/api/${process.env.REACT_APP_API_VERSION}/purchase/apply-token`,
  getAuthCode: `/api/${process.env.REACT_APP_API_VERSION}/purchase/oauth-dana`,
  getSubscriptionUser: `/api/${process.env.REACT_APP_API_VERSION}/users/subscription-user/`,
  singleStampTemplate: `/api/${process.env.REACT_APP_API_VERSION}/single-stamp/stamp-template-process-company/`,
  services: {
    check: `/api/${process.env.REACT_APP_API_VERSION}/services/generate-id`,
    stamp: `/api/${process.env.REACT_APP_API_VERSION}/services/stamp`
  },
  vida : {
    embed: `/api/${process.env.REACT_APP_API_VERSION}/vida-psre/esign`,
    update: `/api/${process.env.REACT_APP_API_VERSION}/vida-psre/esign-update`,
    signerProcess: `/api/${process.env.REACT_APP_API_VERSION}/esign-only/signer-process`,
  },
  stamp: {
    esign: {
      endProcess : `/api/${process.env.REACT_APP_API_VERSION}/esign-only/end-process`,
      embedProcess : `/api/${process.env.REACT_APP_API_VERSION}/esign-only/embed-process`
    }
  },
  referral : {
    history: `/api/referral/history`,
    submit: `/api/referral/submit`,
    check: `/api/referral/check`,
    redeemHistory: `/api/referral/history-redeem`,
    submitRedeem: `/api/referral/redeem-point`
  },
  user: {
    checkByDigiFriend: `/api/${process.env.REACT_APP_API_VERSION}/users/user/`
  },
  transfer : {
    check: `/api/transfer-kuota/check-kuota-transfer-c2c`,
    submit: `/api/transfer-kuota/transfer-kuota-c2c`,
    history: `/api/transfer-kuota/history-transfer-c2c`
  }
}

export function generateGreetings() {
  const currentHour = moment().format("HH")
  if (currentHour >= 3 && currentHour < 12) {
      return "Good Morning!"
  } else if (currentHour >= 12 && currentHour < 15) {
      return "Good Afternoon!"
  }   else if (currentHour >= 15 && currentHour < 20) {
      return "Good Evening!"
  } else if (currentHour >= 20 || currentHour < 3) {
      return "Good Evening!"
  } else {
      return "Hello"
  }
}