// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const submitRegister = createAsyncThunk("register", async (data) => {
  await axios.post("/api/register", data)
  return data
})

export const registerSlice = createSlice({
  name: "register",
  initialState: {
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    tdp: "",
    siup: "",
    npwp: "",
    accountNumber: "",
    companyName: "",
    bank: "",
    type: "",
    noKTP: ""
  },
  reducers: {
    accountInfo: (state, action) => {
      state.name = action.payload.name
      state.email = action.payload.email
      state.phoneNumber = action.payload.phone
    },
    securityInfo: (state, action) => {
      state.password = action.payload.password
    },
    additionalInfo: (state, action) => {
      state.type = action.payload.type
      state.accountNumber = action.payload.accountNumber
      state.bank = action.payload.bank
      state.companyName = action.payload.companyName
      state.noKTP = action.payload.noKTP
      state.npwp = action.payload.npwp
      state.siup = action.payload.siup
      state.tdp = action.payload.tdp
    },
  },
  extraReducers: {},
})
export const { accountInfo, securityInfo, additionalInfo } = registerSlice.actions
export default registerSlice.reducer
