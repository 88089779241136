// ** Icons Import
import { Home, Activity, ShoppingCart } from "react-feather"

export default [
  {
    id: "dashboard",
    title: "",
    icon: <Home size={14} />,
    navLink: "/dashboard",
    children: [],
  },
]
