import { lazy } from "react"
const Plan = lazy(() => import("../../views/emeterai/plans"))
const TransferPersonal = lazy(() => import("../../views/emeterai/quota/transfer-personal"))

const PlanRoutes = [
  {
    path: "/plan",
    element: <Plan />
  },
  {
    path: "/transfer",
    element: <TransferPersonal />
  }
]

export default PlanRoutes
