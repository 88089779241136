// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@configs/axios"
const ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/users`
const entity = "User"

export const getAllUsers = createAsyncThunk("users/view", async () => {
  const user = JSON.parse(localStorage.getItem("userData"))
  const endpoint = `/api/${process.env.REACT_APP_API_VERSION}/users/user-company`
  const response = await axios.get(endpoint, {
    headers: {
      Authorization: `Bearer ${user.accessToken}`
    }
  })
  const filter = response.data.data
  const result = filter.filter((item) => item.id != user.id)
  return result
})

export const addUser = createAsyncThunk(
  "users/add",
  async (users, { dispatch, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"))
    try {
      await axios.post(ENDPOINT, users, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`
        }
      })
      await dispatch(getAllUsers())
      return users
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const deleteUser = createAsyncThunk(
  "users/delete",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const user = JSON.parse(localStorage.getItem("userData"))
      await axios.delete(`${ENDPOINT}/${id}`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`
        }
      })
      await dispatch(getAllUsers())
      return id
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const updateUser = createAsyncThunk(
  "users/update",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = JSON.parse(localStorage.getItem("userData"))
      const res = await axios.patch(`${ENDPOINT}/${data.id}`, data, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`
        }
      })
      await dispatch(getAllUsers())
      return res
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const getDetailUser = createAsyncThunk("users/detail", async () => {
  const user = JSON.parse(localStorage.getItem("userData"))
  const endpoint = `/api/${process.env.REACT_APP_API_VERSION}/users/${user.id}`
  const response = await axios.get(endpoint, {
    headers: {
      Authorization: `Bearer ${user.accessToken}`
    }
  })
  const result = response.data.data
  return result
})

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    allData: [],
    loading: true,
    loading_form: true,
    error: "",
    success: "",
    modal: false,
    detail: {}
  },
  reducers: {
    toggleModal: (state, action) => {
      state.modal = !state.modal
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.allData = action.payload
      state.loading = false
      state.loading_form = false
      state.error = ""
    })
    builder.addCase(addUser.rejected, (state, action) => {
      console.log(action.payload)
      state.error = action.payload.message ? action.payload.message : action.payload.errors.status
      state.loading_form = false
      state.success = ""
      state.modal = true
    })
    builder.addCase(addUser.pending, (state, action) => {
      state.loading_form = true
      state.success = ""
      state.error = ""
    })
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.loading_form = false
      state.error = ""
      state.success = `Berhasil tambah data ${entity}`
      state.modal = false
    })
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading_form = false
      state.error = action.payload.errors.status
      state.success = ""
      state.modal = true
    })
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.loading_form = false
      state.error = ""
      state.success = `Berhasil update data ${entity}`
      state.modal = false
    })
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loading_form = false
      state.error = action.payload.errors.status
      state.success = ""
    })
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.loading_form = false
      state.error = ""
      state.success = `Berhasil hapus data ${entity}`
    })
    builder.addCase(getDetailUser.fulfilled, (state, action) => {
      state.loading = false
      state.loading_form = false
      state.error = ""
      state.detail = action.payload
    })
  }
})

export const {
  toggleModal,
} = usersSlice.actions

export default usersSlice.reducer
