import mock from "../mock"

// ** Utils
import { paginateArray } from "../utils"

const data = {
  transfer: [
    {
      id: 1,
      department: "Finance",
      quota: "10",
      date: "2022-12-01",
    },
    {
      id: 2,
      department: "HR",
      quota: "20",
      date: "2022-12-02",
    },
    {
      id: 3,
      department: "TAX",
      quota: "100",
      date: "2022-12-05",
    },
  ],
}

// GET ALL DATA
mock.onGet("/api/transfer/list/all-data").reply(200, data.transfer)
