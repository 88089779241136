// ** Icons Import
import {
  Box,
  Mail,
  User,
  Circle,
  Shield,
  Calendar,
  FileText,
  CheckSquare,
  ShoppingCart,
  MessageSquare,
  File,
  Grid,
  Users,
  Archive,
  Edit3,
  FilePlus
} from "react-feather"

export default [
  // {
  //   header: "Master Data"
  // },
  {
    id: "master",
    title: "Master Data",
    icon: <Grid size={14} />,
    children: [
      {
        id: "user",
        title: "User",
        icon: <Users size={14} />,
        navLink: "/master/user"
      },
      // {
      //   id: "permission",
      //   title: "Permission",
      //   icon: <CheckSquare />,
      //   navLink: "/master/permission",
      // },
      {
        id: "department",
        title: "Department",
        icon: <Archive size={14} />,
        navLink: "/master/department"
      },
      // {
      //   id: "esign",
      //   title: "eSign",
      //   icon: <Edit3 size={14} />,
      //   navLink: "/master/esign"
      // },
      // {
      //   id: "bulkStamp",
      //   title: "Document Template",
      //   icon: <FilePlus size={14} />,
      //   navLink: "/master/document-template"
      // }
    ]
  }
]
