// ** Icons Import
import { Home, Activity, ShoppingCart, HelpCircle } from "react-feather"

export default [
  {
    header: "Support",
  },
  {
    id: "support",
    title: "Support",
    icon: <HelpCircle />,
    children: [
      {
        id: "faq",
        title: "FAQ",
        // icon: <Activity />,
        navLink: "/support/faq",
      },
      {
        id: "knowledgeBase",
        title: "Knowledge Base",
        // icon: <ShoppingCart />,
        navLink: "/support/knowledge-base",
      },
      {
        id: "raiseSupport",
        title: "Ticket",
        // icon: <ShoppingCart />,
        navLink: "/support/ticket",
      },
    ],
  },
]
