// ** Icons Import
import { Home, Activity, ShoppingCart, Box, FileText, File, FilePlus } from "react-feather"

export default [
  // {
  //   header: "Stamp"
  // },
  {
    id: "stamp",
    title: "My e-Document",
    icon: <FileText size={14} />,
    children: [
      {
        id: "singleStamp",
        title: "Single Stamp",
        icon: <File size={14} />,
        navLink: "/stamp"
      },
      {
        id: "bulkStamp",
        title: "Bulk Stamp",
        icon: <FilePlus size={14} />,
        navLink: "/stamp/bulk"
      }
      // {
      //   id: "bulkStamp",
      //   title: "Bulk Stamp",
      //   // icon: <ShoppingCart />,
      //   navLink: "/stamp/bulk",
      // },
    ]
  }
]
