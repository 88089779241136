// ** Navigation imports
import dashboards from "../dashboards"
import document from "../document"
import master from "../masterPersonal1"
import support from "../support"
import stamp from "../stampPersonal"
import plans from "../plans"

// ** Merge & Export
export default [
  ...dashboards,
  ...master,
  ...stamp,
  ...plans,
  ...document,
  ...support,
]
