import { lazy } from "react"

const Dashboard = lazy(() => import("../../views/emeterai/dashboard"))
const DashboardRoutes = [
  {
    path: "/dashboard",
    element: <Dashboard />
  }
]

export default DashboardRoutes
