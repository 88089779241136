import React, { useState } from "react"
import { ChevronDown, Grid } from "react-feather"
import { Link, useLocation, useNavigate } from "react-router-dom"
import './style.css'

const Menu = ({ open, children }) => {
  return (
    <div
      className={`dropdown-menu dropdown-menu-end ${open && `show`}`}
      data-bs-popper="none"
    >
      {children.map((item, key) => (
        <Link className="dropdown-item" to={item.navLink} key={key}>
          <span className="align-middle">{item.title}</span>
        </Link>
      ))}
    </div>
  )
}

const Item = ({ title, children, icon, link, id }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const sub = location.pathname.split("/")

  const goTo = (to) => {
    navigate(to)
  }

  const [openMenu, setOpenMenu] = useState(false)
  return (
    <li
      className="nav-item d-none d-lg-block me-25"
    >
      {children?.length > 0 ? (
        // <div
        //   className={`card shadow-none border cursor-pointer mb-0 menu-utama ${
        //     sub[1].includes(id) ? `bg-gradient-primary` : ``
        //   }`}
        // >
        //   <div
        //     className="card-body p-50 w-100"
        //     onClick={() => setOpenMenu(!openMenu)}
        //   >
        //     <div className="d-flex flex-row">
        //       <div className="dropdown">
        //         <button
        //           className={`btn-icon btn btn-sm dropdown-toggle ${
        //             sub[1].includes(id) ? `text-white active` : ``
        //           }`}
        //           type="button"
        //           data-bs-toggle="dropdown"
        //           aria-haspopup="true"
        //           aria-expanded="true"
        //         >
        //           <span className="menu-atas">{title}</span>
        //         </button>
        //         <Menu open={openMenu} children={children} />
        //       </div>
        //     </div>
        //   </div>
        // </div>
        <div className="navigation-item-container">
          <div className={`d-flex align-items-center navigation-item ${sub[1].includes(id) ? `active` : ``}`} onClick={() => setOpenMenu(!openMenu)}>
            {icon}
            <div className="ms-25 fw-bolder">
              {title}
            </div>
            <div className="ms-25">
              <ChevronDown size={14} />
            </div>
          </div>
          <div className="navigation-menu">
              <div className="d-flex flex-column">
                {children.map((item, key) => (
                  <div className="navigation-menu-child d-flex align-items-center" key={key} onClick={(e) => goTo(item.navLink)}>
                    {item.icon}
                    <div className="ms-1">
                      {item.title}
                    </div>
                  </div>
                ))}  
              </div>
          </div>
        </div>
      ) : (
        // <div
        //   className={`card shadow-none border cursor-pointer mb-0 menu-utama ${
        //     sub[1].includes(id) ? `bg-gradient-primary active` : ``
        //   }`}
        // >
        //   <div
        //     className="card-body p-50 h-100 w-100"
        //     onClick={() => setOpenMenu(!openMenu)}
        //   >
        //     <div className="d-flex flex-row">
        //       <div className="dropdown">
        //         <button
        //           className={`btn-icon btn btn-sm dropdown-toggle dashboard ${
        //             sub[1].includes(id) ? `text-white active` : ``
        //           }`}
        //           type="button"
        //           style={{ overflow: "hidden" }}
        //           onClick={() => navigate(link)}
        //         >
        //           <span className="menu-atas">{title}</span>
        //         </button>
        //       </div>
        //     </div>
        //   </div>
        // </div>
        <div className={`d-flex align-items-center navigation-item ${sub[1].includes(id) ? `active` : ``}`} onClick={(e) => goTo(link)}>
          {icon}
          <div className="ms-25 fw-bolder">
            {title}
          </div>
        </div>
      )}
    </li>
  )
}

const CustomNavbar = ({ menuData }) => {
  return (
    <ul className="nav navbar-nav bookmark-icons">
      {menuData?.map((item, key) => (
        <Item
          title={item.title}
          key={key}
          children={item.children}
          icon={item.icon}
          link={item.navLink}
          id={item.id}
        />
      ))}
    </ul>
  )
}

export default CustomNavbar
