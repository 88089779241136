// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@configs/axios"
const ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/departement`
const entity = "Depertemen"

export const getAllDataDepartment = createAsyncThunk(
  "department/view",
  async () => {
    const user = JSON.parse(localStorage.getItem("userData"))

    const response = await axios.get(ENDPOINT, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    return response.data.data
  }
)

export const getDepartmentWithUsers = createAsyncThunk(
  "department/users",
  async () => {
    const user = JSON.parse(localStorage.getItem("userData"))
    const endpoint = `/api/${process.env.REACT_APP_API_VERSION}/users/get/by/${user.company_id}`

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    return response.data.data
  }
)

export const addDepartment = createAsyncThunk(
  "department/add",
  async (department, { dispatch, rejectWithValue }) => {
    try {
      const user = JSON.parse(localStorage.getItem("userData"))
      await axios.post(ENDPOINT, department, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
      await dispatch(getAllDataDepartment())
      await dispatch(getDepartmentWithUsers())
      return department
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const deleteDeparment = createAsyncThunk(
  "department/delete",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const user = JSON.parse(localStorage.getItem("userData"))
      await axios.delete(`${ENDPOINT}/${id}`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
      await dispatch(getAllDataDepartment())
      await dispatch(getDepartmentWithUsers())

      return id
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const updateDepartment = createAsyncThunk(
  "department/update",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = JSON.parse(localStorage.getItem("userData"))
      const res = await axios.patch(
        `${ENDPOINT}/${data.departement_id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      )
      await dispatch(getAllDataDepartment())
      await dispatch(getDepartmentWithUsers())
      return res
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const masterDepartmentSlice = createSlice({
  name: "department",
  initialState: {
    allData: [],
    withUser: [],
    loading: true,
    loading_form: true,
    error: "",
    success: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllDataDepartment.fulfilled, (state, action) => {
      state.allData = action.payload
      state.loading = false
      state.success = ``
      state.error = ``
    })
    builder.addCase(getDepartmentWithUsers.fulfilled, (state, action) => {
      state.withUser = action.payload
    })
    builder.addCase(addDepartment.rejected, (state, action) => {
      state.error = action.payload.errors.status
      state.loading_form = false
      state.success = ""
    })
    builder.addCase(addDepartment.pending, (state, action) => {
      state.loading_form = true
    })
    builder.addCase(addDepartment.fulfilled, (state, action) => {
      state.loading_form = false
      state.error = ""
      state.success = `Berhasil tambah data ${entity}`
    })
    builder.addCase(updateDepartment.rejected, (state, action) => {
      state.loading_form = false
      state.error = action.payload.errors.status
      state.success = ""
    })
    builder.addCase(updateDepartment.fulfilled, (state, action) => {
      state.loading_form = false
      state.error = ""
      state.success = `Berhasil update data ${entity}`
    })
    builder.addCase(deleteDeparment.rejected, (state, action) => {
      state.loading_form = false
      state.error = action.payload.message
      state.success = ""
    })
    builder.addCase(deleteDeparment.fulfilled, (state, action) => {
      state.loading_form = false
      state.error = ""
      state.success = `Berhasil hapus data ${entity}`
    })
  },
})

export default masterDepartmentSlice.reducer
