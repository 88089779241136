// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@configs/axios"
const entity = "Config"

export const getConfig = createAsyncThunk(
  "config/get",
  async () => {
    const user = JSON.parse(localStorage.getItem("userData"))
    const ENDPOINT = `/api/master-status/master-status-list`
    const response = await axios.get(ENDPOINT, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    const result = response.data.data
    const QUOTA_THRESOLD_EMET = result.find((item) => item.status_group == 'threshold_quota_warning')
    const MAX_GAUGE = result.find((item) => item.status_group == 'max_gauge_personal')
    return {
      //   QUOTA_THRESOLD_EMET: QUOTA_THRESOLD_EMET.status_name,
      QUOTA_THRESOLD_EMET: parseInt(QUOTA_THRESOLD_EMET.status_name),
      QUOTA_THRESOLD_ESIGN: parseInt(QUOTA_THRESOLD_EMET.status_name),
      // QUOTA_THRESOLD_ESIGN: parseInt(QUOTA_THRESOLD_EMET.status_name),
      MAX_GAUGE: parseInt(MAX_GAUGE.status_name),
    }
  }
)

export const configSlice = createSlice({
  name: "config",
  initialState: {
    QUOTA_THRESOLD_EMET: 0,
    QUOTA_THRESOLD_ESIGN: 0,
    MAX_GAUGE: 0,
    error: "",
    success: "",
    loading_form: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConfig.fulfilled, (state, action) => {
      state.QUOTA_THRESOLD_EMET = action.payload.QUOTA_THRESOLD_EMET
      state.QUOTA_THRESOLD_ESIGN = action.payload.QUOTA_THRESOLD_ESIGN
      state.MAX_GAUGE = action.payload.MAX_GAUGE
    })
  },
})

export default configSlice.reducer
