// ** Icons Import
import { Home, Activity, File, Folder } from "react-feather"

export default [
  {
    id: "document",
    title: "Digital Locker",
    icon: <Folder size={14} />,
    navLink: "/document/file-manager",
    children: []
  },
]
