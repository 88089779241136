// ** Navigation imports
import dashboards from "../../dashboards"
import document from "../../document"
import master from "../../master"
import support from "../../support"
import stamp from "../../stamp"
import quota from "../../quota"

// ** Merge & Export
export default [
  ...dashboards,
  ...master,
  ...stamp,
  ...quota,
  ...document,
  ...support,
]
