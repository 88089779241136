// ** Icons Import
import { FileText } from "react-feather"

export default [
  {
    id: "stamp",
    title: "My e-Document",
    icon: <FileText size={14} />,
    navLink: "/stamp",
    // children: [
    //   {
    //     id: "singleStamp",
    //     title: "Single Stamp",
    //     icon: <File />,
    //     navLink: "/stamp"
    //   }
    //   // {
    //   //   id: "singleStamp",
    //   //   title: "Single Dev",
    //   //   icon: <Activity />,
    //   //   navLink: "/stamp/dev",
    //   // },
    //   // {
    //   //   id: "bulkStamp",
    //   //   title: "Bulk Stamp",
    //   //   icon: <ShoppingCart />,
    //   //   navLink: "/stamp/bulk",
    //   // },
    //   // {
    //   //   id: "bulkStamp",
    //   //   title: "Bulk Stamp Dev",
    //   //   icon: <ShoppingCart />,
    //   //   navLink: "/stamp/bulk/dev",
    //   // },
    // ]
  }
]
