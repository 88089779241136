import { lazy } from "react"
const Success = lazy(() => import("../../views/emeterai/payment/success"))
const Failed = lazy(() => import("../../views/emeterai/payment/failed"))

const PaymentRoutes = [
  {
    path: "/payment/success",
    element: <Success />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/payment/failed",
    element: <Failed />,
    meta: {
      layout: "blank"
    }
  }
]

export default PaymentRoutes
