// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@configs/axios"

export const AllTransfer = createAsyncThunk("quota/transfer", async () => {
  const user = JSON.parse(localStorage.getItem("userData"))
  let ENDPOINT = ``
  if (user.role == "admin" || user.role == "admin-company") {
    ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/quota/quota-company`
  } else if (user.role == "user") {
    ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/quota/quota-departement`
  } else {
    ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/quota/quota-user`
  }
  const response = await axios.get(ENDPOINT, {
    headers: {
      Authorization: `Bearer ${user.accessToken}`
    }
  })

  const resHistory = await axios.get(
    `/api/${process.env.REACT_APP_API_VERSION}/quota/history-quota-company`,
    {
      headers: {
        Authorization: `Bearer ${user.accessToken}`
      }
    }
  )

  const chartUnallocated = await axios.get(
    `/api/${process.env.REACT_APP_API_VERSION}/quota/data-chart-quota`,
    {
      headers: {
        Authorization: `Bearer ${user.accessToken}`
      }
    }
  )

  if (user.role == "admin" || user.role == "admin-company") {
    return {
      unallocated: response.data.data.departementQuota.remain,
      allocated: response.data.data.departementQuota.usage.total,
      stamp: response.data.data.departementQuota.stamp.total,
      total: response.data.data.departementQuota.total.total,
      draft: response.data.data.departementQuota.draft.total,
      adminUnallocated: response.data.data.companyQuota.remain,
      adminAllocated: response.data.data.companyQuota.usage.total,
      adminStamp: response.data.data.companyQuota.stamp.total,
      adminTotal: response.data.data.companyQuota.total.total,
      adminDraft: 0,
      history: resHistory.data.data,
      quotaDepartmentAdmin: response.data.data.departementQuota.remain,
      chartUsage: response.data.data.companyQuota.chartUsage,
      chartRemain: response.data.data.companyQuota.chartRemain,
      chartUnallocated: chartUnallocated.data.data,
      esign: 0
    }
  } else if (user.role == "user" && user.user_type != "personal") {
    return {
      unallocated: response.data.data.remain,
      allocated: response.data.data.usage.total,
      stamp: response.data.data.stamp.total,
      total: response.data.data.total.total,
      draft: response.data.data.draft.total,
      history: [],
      quotaDepartmentAdmin: 0,
      chartUsage: response.data.data.chartUsage,
      chartRemain: response.data.data.chartRemain,
      chartUnallocated: [],
      esign: 0
    }
  } else {
    if (user.is_parent == 0) {
      return {
        unallocated: response.data.data.remain_child,
        // unallocated: 0,
        allocated: response.data.data.usage_child.total,
        stamp: response.data.data.stamp_child.total,
        total: response.data.data.total_child.total,
        draft: 0,
        history: [],
        quotaDepartmentAdmin: 0,
        chartUsage: null,
        chartRemain: null,
        chartUnallocated: [],
        esign: response.data.data.esign.total
        // esign: 0
      }
    } else {
      return {
        // unallocated: 0,
        unallocated: response.data.data.remain,
        allocated: response.data.data.usage.total,
        stamp: response.data.data.stamp.total,
        total: response.data.data.total.total,
        draft: 0,
        history: [],
        quotaDepartmentAdmin: 0,
        chartUsage: null,
        chartRemain: null,
        chartUnallocated: [],
        esign: response.data.data.esign.total
        // esign: 0
      }
    }
  }
})

export const CreateTransfer = createAsyncThunk(
  "quota/create-transfer",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = JSON.parse(localStorage.getItem("userData"))
      const ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/quota/transfer-quota-company`
      const response = await axios.post(ENDPOINT, data, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`
        }
      })
      await dispatch(AllTransfer())
      return response
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const ReturnTransfer = createAsyncThunk(
  "quota/return-transfer",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = JSON.parse(localStorage.getItem("userData"))
      const ENDPOINT = `/api/${process.env.REACT_APP_API_VERSION}/quota/return-quota-department`
      const response = await axios.post(ENDPOINT, data, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`
        }
      })
      await dispatch(AllTransfer())
      return response
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const transferSlice = createSlice({
  name: "transfer",
  initialState: {
    allData: [],
    loading: true,
    loading_form: true,
    error: "",
    success: "",
    allocated: "",
    unallocated: "",
    total: "",
    stamp: "",
    draft: "",
    esign: "",
    adminAllocated: "",
    adminUnallocated: "",
    adminTotal: "",
    adminStamp: "",
    adminDraft: "",
    chartAllocated: "",
    chartUnallocated: "",
    chartDepartement: [],
    quotaDepartmentAdmin: ""
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AllTransfer.fulfilled, (state, action) => {
      state.loading = false
      state.success = ``
      state.error = ``
      state.allocated = action.payload.allocated
      state.unallocated = action.payload.unallocated
      state.total = action.payload.total
      state.stamp = action.payload.stamp
      state.draft = action.payload.draft
      state.adminAllocated = action.payload.adminAllocated
      state.adminUnallocated = action.payload.adminUnallocated
      state.adminTotal = action.payload.adminTotal
      state.adminStamp = action.payload.adminStamp
      state.adminDraft = action.payload.adminDraft
      state.allData = action.payload.history
      state.quotaDepartmentAdmin = action.payload.quotaDepartmentAdmin
      state.chartAllocated = action.payload.chartUsage
      state.chartUnallocated = action.payload.chartRemain
      state.chartDepartement = action.payload.chartUnallocated
      state.esign = action.payload.esign
      // state.esign = 0
    })
    builder.addCase(AllTransfer.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(AllTransfer.rejected, (state, action) => {
      state.loading = false
    })
    builder.addCase(CreateTransfer.fulfilled, (state, action) => {
      state.loading_form = false
      state.error = ""
      state.success = `Berhasil transfer kuota`
    })
    builder.addCase(CreateTransfer.rejected, (state, action) => {
      state.loading_form = false
      state.error = action.payload.message
      state.success = ""
    })
    builder.addCase(ReturnTransfer.fulfilled, (state, action) => {
      state.loading_form = false
      state.error = ""
      state.success = `Berhasil return kuota`
    })
    builder.addCase(ReturnTransfer.rejected, (state, action) => {
      state.loading_form = false
      state.error = action.payload.message
      state.success = ""
    })
  }
})

export default transferSlice.reducer
