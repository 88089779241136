// ** Icons Import
import {
    Box,
    Mail,
    User,
    Circle,
    Shield,
    Calendar,
    FileText,
    CheckSquare,
    ShoppingCart,
    MessageSquare,
    File,
    Grid,
    Users,
    Archive,
    Edit3
  } from "react-feather"
  
  export default [
    {
      id: "master",
      title: "Master Data",
      icon: <Grid size={14} />,
      children: [
        {
          id: "user",
          title: "User",
          icon: <Users size={14} />,
          navLink: "/master/user"
        },
        {
          id: "department",
          title: "Department",
          icon: <Archive size={14} />,
          navLink: "/master/department"
        },
        // {
        //   id: "esign",
        //   title: "eSign",
        //   icon: <Edit3 size={14} />,
        //   navLink: "/master/esign"
        // }
      ]
    }
  ]
  