// ** Icons Import
import {
  Edit3,
  Grid
} from "react-feather"

export default [
  {
    id: "master",
    title: "Master Data",
    icon: <Grid size={14} />,
    children: [
      {
        id: "esign",
        title: "eSign",
        icon: <Edit3 size={14} />,
        navLink: "/master/esign"
      }
    ]
  }
]
