// ** Icons Import
import { Home } from "react-feather"

export default [
  // {
  //   header: "Dashboard",
  // },
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Home size={14} />,
    navLink: "/dashboard",
    // children: [],
  },
]
