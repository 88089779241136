// ** Dropdowns Imports
// import IntlDropdown from './IntlDropdown'
// import CartDropdown from "./CartDropdown"
import UserDropdown from "./UserDropdown"
// import NavbarSearch from "./NavbarSearch"
import NotificationDropdown from "./NotificationDropdown"

// ** Third Party Components
import { Sun, Moon } from "react-feather"

// ** Reactstrap Imports
import { useLocation } from "react-router-dom"
import { NavItem, NavLink, UncontrolledTooltip } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { AllTransfer } from "../../../../views/emeterai/quota/transfer/store"
import { CheckResponse } from "./store"
import { getSubs } from "../../../../layouts/store"

const NavbarUser = (props) => {
  // ** Props
  const location = useLocation()
  const { skin, setSkin } = props
  const plan = useSelector((store) => store.plan)
  const store = useSelector((store) => store.transfer)
  const navbar = useSelector((store) => store.quotaLeft)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSubs())
    dispatch(AllTransfer())
    dispatch(CheckResponse())
  }, [location.pathname])

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === "dark") {
      return <Sun className="ficon" onClick={() => setSkin("light")} />
    } else {
      return <Moon className="ficon" onClick={() => setSkin("dark")} />
    }
  }

  const user = JSON.parse(localStorage.getItem("userData"))

  const Indicator = ({ bg, string }) => {
    return (
      <>
        <div
          className={`${bg}`}
          style={{
            height: "20px",
            width: "20px",
            boxShadow: "inset 0 0 5px #555"
          }}
          id="positionTop"
        ></div>
        <UncontrolledTooltip placement="top" target="positionTop">
          {string}
        </UncontrolledTooltip>
      </>
    )
  }

  const bgIndicator = (time) => {
    if (time == -1) {
      return `bg-secondary`
    }
    if (time > 0 && time <= 5) {
      return `bg-success-custom`
    }
    if (time >= 6 && time <= 10) {
      return `bg-warning`
    }
    if (time > 10) {
      return `bg-danger`
    }
  }

  const infoIndicator = (time) => {
    if (time == -1) {
      return `Checking Status Connection..`
    }
    if (time > 0 && time <= 5) {
      return `All Good`
    }
    if (time >= 6 && time <= 10) {
      return `Poor`
    }
    if (time > 10) {
      return `Heavy Traffic`
    }
  }

  return (
    <ul className="nav navbar-nav align-items-center ms-auto">
      {/* <IntlDropdown /> */}
      {/* <NavItem className="d-none d-lg-block">
        <NavLink className="nav-link-style">
          <ThemeToggler />
        </NavLink>
      </NavItem> */}
      {/* <NavbarSearch /> */}
      {/* <CartDropdown /> */}
      {/* <NotificationDropdown /> */}

      <Indicator
        bg={bgIndicator(navbar.peruriResponseTime)}
        string={infoIndicator(navbar.peruriResponseTime)}
      />

      {/* <button
        className={`btn btn-sm btn-outline-primary btn-outline waves-effect waves-float waves-light`}
        type="button"
      >
        <span className="menu-atas fw-bolder">
          {user?.role == "admin"
            ? store.quotaDepartmentAdmin
            : store.unallocated}{" "}
          <span style={{ fontSize: "10px" }}>eMeterai</span>
        </span>
      </button>
      {user?.user_type == "personal" && (
        <button
          className={`ms-1 btn btn-sm btn-outline-danger btn-outline waves-effect waves-float waves-light`}
          type="button"
        >
          <span className="menu-atas fw-bolder">
            {store.esign ? store.esign : 0}{" "}
            <span className="menu-atas fw-bolder" style={{ fontSize: "10px" }}>
              eSign
            </span>
          </span>
        </button>
      )} */}
      <UserDropdown store={store} plan={plan} />
    </ul>
  )
}
export default NavbarUser
