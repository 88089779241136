import mock from "../mock"

// ** Utils
import { paginateArray } from "../utils"

const data = {
  purchase: [
    {
      id: 1,
      invoiceNumber: "INV/20221116/ESIGN/1668581678",
      puchaseBy: "Admin",
      date: "2022-12-01",
      qty: 10,
      amount: "Rp. 100.000",
      status: "Paid",
    },
    {
      id: 2,
      invoiceNumber: "INV/20221116/ESIGN/1668581679",
      puchaseBy: "Admin",
      date: "2022-11-01",
      qty: 3,
      amount: "Rp. 30.000",
      status: "Expired",
    },
    {
      id: 3,
      invoiceNumber: "INV/20221116/ESIGN/1668581674",
      puchaseBy: "Admin",
      date: "2022-11-01",
      qty: 3,
      amount: "Rp. 30.000",
      status: "Pending",
    },
  ],
}

// GET ALL DATA
mock.onGet("/api/purchase/list/all-data").reply(200, data.purchase)
