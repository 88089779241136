import { lazy } from "react"

const Setting = lazy(() => import("../../views/emeterai/setting"))

const SettingRoutes = [
  {
    path: "/setting/:id",
    element: <Setting />
  }
]

export default SettingRoutes
