import { lazy } from "react"
const Stamp = lazy(() => import("../../views/emeterai/stamp/single/data"))
const StampList = lazy(() => import("../../views/emeterai/stamp/single/list"))
const SingleStamp = lazy(() => import("../../views/emeterai/stamp/single"))
const SingleTemplate = lazy(() => import("../../views/emeterai/stamp/template"))
const SingleStampTemplate = lazy(() =>
  import("../../views/emeterai/stamp/single/template")
)
const DraftStamp = lazy(() => import("../../views/emeterai/stamp/single/draft"))
const Esign = lazy(() => import("../../views/emeterai/stamp/single/esign"))
const BulkStamp = lazy(() => import("../../views/emeterai/stamp/bulk"))
const BulkList = lazy(() => import("../../views/emeterai/stamp/bulk/data"))
const BulkStampList = lazy(() => import("../../views/emeterai/stamp/bulk/list"))
const BulkDetail = lazy(() => import("../../views/emeterai/stamp/bulk/detail"))
const CreateTemplate = lazy(() =>
  import("../../views/emeterai/stamp/bulk/create-template")
)
const UseTemplate = lazy(() =>
  import("../../views/emeterai/stamp/bulk/use-template")
)
const EditTemplate = lazy(() =>
  import("../../views/emeterai/stamp/bulk/edit-template")
)
const EsignStamp = lazy(() => import("../../views/emeterai/stamp/e-sign"))

const StampRoutes = [
  {
    path: "/stamp/backup",
    element: <Stamp />
  },
  {
    path: "/stamp/single",
    element: <SingleStamp />
  },
  {
    path: "/stamp/single/template",
    element: <SingleStampTemplate />
  },
  {
    path: "/stamp",
    element: <StampList />,
    meta: {
      appLayout: true,
      className: "email-application"
    }
  },
  {
    path: "/stamp/single/draft/:id",
    element: <DraftStamp />
  },
  {
    path: "/stamp/single/esign/:id",
    element: <Esign />
  },
  {
    path: "/stamp/bulk/backup",
    element: <BulkList />
  },
  {
    path: "/stamp/bulk",
    element: <BulkStampList />,
    meta: {
      appLayout: true,
      className: "email-application"
    }
  },
  {
    path: "/stamp/document-template",
    element: <SingleTemplate />,
    meta: {
      appLayout: true,
      className: "email-application"
    }
  },
  {
    path: "/stamp/bulk/:id",
    element: <BulkDetail />
  },
  {
    path: "/stamp/bulk/template",
    element: <BulkStamp />
  },
  {
    path: "/stamp/bulk/create-template",
    element: <CreateTemplate />
  },
  {
    path: "/stamp/bulk/use-template/:id",
    element: <UseTemplate />
  },
  {
    path: "/stamp/bulk/edit-template/:id",
    element: <EditTemplate />
  },
  {
    path: "/stamp/e-sign",
    element: <EsignStamp />
  }
]

export default StampRoutes
