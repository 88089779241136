import mock from "../mock"

// ** Utils
import { paginateArray } from "../utils"

const data = {
  template: [
    {
      id: 1,
      nama: "Template 1",
      type: "Surat Dokumen",
      esign: {
        x: 100,
        y: 100,
        page: 2,
      },
      emeterai: {
        x: 50,
        y: 200,
        page: 1,
      },
      tanggal: "2022-11-10",
    },
    {
      id: 2,
      nama: "Template 2",
      type: "Surat Berharga",
      esign: {
        x: 100,
        y: 100,
        page: 2,
      },
      emeterai: {
        x: 50,
        y: 200,
        page: 1,
      },
      tanggal: "2022-11-10",
    },
  ],
}

// GET ALL DATA
mock.onGet("/api/template/list/all-data").reply(200, data.template)
